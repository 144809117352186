<template>
  <section>
    <div class="parallax-container">
      <div class="parallax">
        <div id="parallax-background"></div>
        <div id="parallax-logo">
          <img src="@/assets/svg/parallax-logo.svg" alt="" />
        </div>
        <div id="parallax-vectors">
          <img src="@/assets/svg/parallax-vectors.svg" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="about-container">
      <div ref="hiddenElements" class="about-text-container hidden">
        <h1 class="land_title">О НАС</h1>
        <p class="land_paragraph">
          Орион-Трейд — торговая площадка, которая помогает предпринимателям
          совершать оптовые закупки товаров на внутреннем рынке Китая.
        </p>
        <p class="land_paragraph">Компания была основана в 2021 году.</p>
        <p class="land_paragraph">
          За время своего существования мы наладили беспрепятственную поставку
          товаров, открыли склад в Китае и начинем приобретать сотни постоянных
          клиентов из России и стран СНГ.
        </p>
      </div>
      <div ref="hiddenElements" class="about-image-container hidden">
        <img src="@/assets/img/teamwork.png" alt="" />
      </div>
    </div>
  </section>
  <section>
    <div class="benefits-container">
      <h1 class="land_title white">ПРЕИМУЩЕСТВА НАШЕЙ КОМПАНИИ</h1>
      <div class="benefits-grid">
        <div class="benefit">
          <div class="benefit_icon">
            <img src="@/assets/img/awards.svg" alt="" />
          </div>
          <p
            ref="hiddenElementsOpacity"
            class="land_paragraph white hidden-opacity"
          >
            Мы предоставляем возможность заказа товара напрямую у завода, с
            персонализацией по техническим характеристикам и логотипу
          </p>
        </div>
        <div class="benefit">
          <div class="benefit_icon">
            <img src="@/assets/img/awards.svg" alt="" />
          </div>
          <p
            ref="hiddenElementsOpacity"
            class="land_paragraph white hidden-opacity"
          >
            Наша компания предоставляет широкий спектр услуг в области логистики
            и доставки товаров из Китая. Мы гарантируем высокое качество наших
            услуг и превосходный уровень обслуживания наших клиентов.
          </p>
        </div>
        <div class="benefit">
          <div class="benefit_icon">
            <img src="@/assets/img/awards.svg" alt="" />
          </div>
          <p
            ref="hiddenElementsOpacity"
            class="land_paragraph white hidden-opacity"
          >
            Одним из наших главных преимуществ является индивидуальный подход к
            каждому клиенту. Мы всегда стараемся понять потребности и требования
            наших клиентов, чтобы предоставить наиболее эффективное и выгодное
            решение для каждого отдельного случая.
          </p>
        </div>
        <div class="benefit">
          <div class="benefit_icon">
            <img src="@/assets/img/awards.svg" alt="" />
          </div>
          <p
            ref="hiddenElementsOpacity"
            class="land_paragraph white hidden-opacity"
          >
            Мы работаем только с надежными и проверенными поставщиками, что
            гарантирует высокое качество товаров и их соответствие заявленным
            требованиям. Наши специалисты тщательно проверяют каждый товар перед
            отправкой, чтобы исключить возможные проблемы и неприятности.
          </p>
        </div>
        <div class="benefit">
          <div class="benefit_icon">
            <img src="@/assets/img/awards.svg" alt="" />
          </div>
          <p
            ref="hiddenElementsOpacity"
            class="land_paragraph white hidden-opacity"
          >
            Кроме того, мы предлагаем полный комплекс услуг, включая сбор и
            группировку заказов, таможенное оформление и доставку до места
            назначения. Это позволяет нашим клиентам значительно сэкономить
            время и средства, получить заказ в кратчайшие сроки и без проблем с
            таможенными процедурами.
          </p>
        </div>
        <div class="benefit">
          <div class="benefit_icon">
            <img src="@/assets/img/awards.svg" alt="" />
          </div>
          <p
            ref="hiddenElementsOpacity"
            class="land_paragraph white hidden-opacity"
          >
            Мы гарантируем быструю и надежную доставку, а также высокий уровень
            сервиса и поддержки. Мы всегда готовы ответить на любые вопросы,
            проконсультировать по всем вопросам и оказать помощь в любых
            ситуациях.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section style="display: none" class="certificates-section">
    <div id="shadow" class="shadow none"></div>
    <h1 class="land_title">ДИПЛОМЫ И СЕРТИФИКАТЫ</h1>
    <div class="carousel-wrapper">
      <img
        id="left"
        class="carousel-arrows"
        src="@/assets/img/arrow-left.svg"
        alt=""
      />
      <div class="carousel">
        <img src="@/assets/img/certificates/cat1.jpg" alt="" />
        <img src="@/assets/img/certificates/cat2.jpg" alt="" />
        <img src="@/assets/img/certificates/cat3.jpg" alt="" />
        <img src="@/assets/img/certificates/cat4.jpg" alt="" />
        <img src="@/assets/img/certificates/cat5.jpg" alt="" />
        <img src="@/assets/img/certificates/cat6.jpg" alt="" />
        <img src="@/assets/img/certificates/cat7.jpg" alt="" />
        <img src="@/assets/img/certificates/cat8.jpg" alt="" />
        <img src="@/assets/img/certificates/cat9.jpg" alt="" />
        <img src="@/assets/img/certificates/cat10.jpg" alt="" />
      </div>
      <img
        id="right"
        class="carousel-arrows"
        src="@/assets/img/arrow-right.svg"
        alt=""
      />
    </div>
  </section>
  <section class="def-section-padding">
    <h1 class="land_title center">как мы работаем</h1>
    <div ref="hiddenElementsOpacity" class="about-work-wrapper hidden-opacity">
      <div class="work-steps-container">
        <h1 class="land_title">1</h1>
        <p class="land_paragraph fnt-sz-24">
          По вашей заявке мы подбираем варианты и направляем вам на согласование
        </p>
      </div>
      <div class="work-steps-container">
        <h1 class="land_title">2</h1>
        <p class="land_paragraph fnt-sz-24">
          После согласования варианта товара мы проводим процедуры по покупке,
          оформлению и сбору заказа
        </p>
      </div>
      <div class="work-steps-container">
        <h1 class="land_title">3</h1>
        <p class="land_paragraph fnt-sz-24">
          Перед отгрузкой товара от производителя в Китае мы проводим оценку
          соответствия комплектности продукции, а также, качества и целостности
          упаковки
        </p>
      </div>
      <div class="work-steps-container">
        <h1 class="land_title">4</h1>
        <p class="land_paragraph fnt-sz-24">
          Выполняем доставку груза до потребителя «под ключ», с учётом всех
          таможенных требований и оптимальности способа доставки
          (морской/сухопутный транспорт, контейнер, ж/д и т.д.)
        </p>
      </div>
      <!-- <div class="num-container">
        <h1 class="land_title" style="margin-bottom: 8vw">1</h1>
        <h1 class="land_title" style="margin-bottom: 8vw">2</h1>
        <h1 class="land_title" style="margin-bottom: 13vw">3</h1>
        <h1 class="land_title">4</h1>
      </div>
      <div class="vertical_line"></div>
      <div class="text-container">
        <p class="land_paragraph fnt-sz-24">
          По вашей заявке мы подбираем варианты и направляем вам на согласование
        </p>
        <p class="land_paragraph fnt-sz-24">
          После согласования варианта товара мы проводим процедуры по покупке,
          оформлению и сбору заказа
        </p>
        <p class="land_paragraph fnt-sz-24">
          Перед отгрузкой товара от производителя в Китае мы проводим оценку
          соответствия комплектности продукции, а также, качества и целостности
          упаковки
        </p>
        <p class="land_paragraph fnt-sz-24">
          Выполняем доставку груза до потребителя «под ключ», с учётом всех
          таможенных требований и оптимальности способа доставки
          (морской/сухопутный транспорт, контейнер, ж/д и т.д.)
        </p> -->
      <!-- </div> -->
    </div>
  </section>
  <!-- <section class="def-section-padding">
    <div ref="hiddenElements" class="guarantee-container hidden">
      <div class="guarantee-icon">
        <img src="@/assets/img/awardv2.svg" alt="" />
      </div>
      <p class="guarantee-text fnt-sz-24">
        Тут информация о возвратах и гарантии. Задача организации, в особенности
        же выбранный нами инновационный путь играет важную роль в формировании
        новых предложений. В своём стремлении улучшить пользовательский опыт мы
        упускаем, что явные признаки победы институционализации будут
        ассоциативно распределены по отраслям. Банальные, но неопровержимые
        выводы, а также многие известные личности лишь добавляют фракционных
        разногласий и описаны максимально подробно.
      </p>
    </div>
  </section> -->
  <section style="margin: 5vw 0">
    <div class="adress-card">
      <div class="card__content">
        <div class="card__content__title">Офис в Екатеринбурге</div>
        <div class="card__line"></div>
        <div class="card__content__row">
          <div class="card__content__subtitle">
            Время работы:
            <span class="card__content__text">пн-пт 9:00-18:00</span>
          </div>
        </div>
        <div class="card__content__row">
          <div class="card__content__subtitle">
            Адрес:
            <span class="card__content__text"
              >г. Екатеринбург, пр. Циолковского 57</span
            >
          </div>
        </div>
        <div class="card__content__row">
          <div class="card__content__subtitle">
            ИНН: <span class="card__content__text">3711053260</span>
          </div>
        </div>
        <div class="card__content__row">
          <div class="card__content__subtitle">
            ОГРН: <span class="card__content__text">1223700007332</span>
          </div>
        </div>
        <div class="card__content__row">
          <div class="card__content__subtitle">
            Почта:
            <span class="card__content__text">contact@trade-orion.ru</span>
          </div>
        </div>
        <div class="card__content__row">
          <div class="card__content__subtitle">
            <img src="@/assets/svg/telephone.svg" /><span
              class="card__content__text"
              >8 999 562 64 71</span
            >
          </div>
        </div>
      </div>
      <div class="card__vidjet">
        <div style="position: relative; overflow: hidden">
          <a
            href="https://yandex.com/maps/54/yekaterinburg/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 0px"
            >Екатеринбург</a
          ><a
            href="https://yandex.com/maps/54/yekaterinburg/house/ulitsa_tsiolkovskogo_57/YkkYcAZiQUMEQFtsfXRxdHpibQ==/?from=mapframe&ll=60.613504%2C56.805088&utm_medium=mapframe&utm_source=maps&z=17.4"
            style="color: #eee; font-size: 12px; position: absolute; top: 14px"
            >Улица Циолковского, 57 на карте Екатеринбурга, ближайшее метро
            Чкаловская — Яндекс Карты</a
          ><iframe
            src="https://yandex.com/map-widget/v1/?from=mapframe&ll=60.613504%2C56.805088&mode=whatshere&whatshere%5Bpoint%5D=60.612863%2C56.805619&whatshere%5Bzoom%5D=17&z=17.4"
            style="
              position: relative;
              width: 95%;
              margin: 0;
              padding: 0;
              border: 1px #0912ec3d solid;
            "
            height="300"
            frameborder="0"
            allowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    var observer, hiddenElements, hiddenElementsOpacity;
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.classList.contains("hidden")) {
            entry.target.classList.add("show");
          }
          if (entry.target.classList.contains("hidden-opacity")) {
            entry.target.classList.add("show-opacity");
          }
        }
      });
    });

    hiddenElements = document.querySelectorAll(".hidden");
    hiddenElementsOpacity = document.querySelectorAll(".hidden-opacity");

    hiddenElements.forEach((el) => observer.observe(el));
    hiddenElementsOpacity.forEach((el) => observer.observe(el));
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
}

.logo img {
  object-fit: cover;
}

.header-container {
  background-color: #ffffff;
  width: 100%;
  height: 48px;
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.6);
  top: 0;
  gap: 10px;
  z-index: 99;
}

.work-steps-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5vw;
}
.work-steps-container h1 {
  margin: 0;
}

.btn-catalog {
  font-family: Open Sans;
  font-weight: 600;
  color: #3c3e72;
  /* font-size: calc(12px + 8 * (100vw / 1412)); */
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 13) + 13px);
  /* font-size: 24px; */
  cursor: pointer;
  text-decoration: none;
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}
.btn {
  font-family: Open Sans;
  font-weight: 400;
  color: #000;
  /* font-size: calc(10px + 10 * (100vw / 1412)); */
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 11) + 11px);
  /* font-size: 20px; */
  cursor: pointer;
  text-decoration: none;
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.nav {
  display: flex;
  align-items: baseline;
}

.nav a + * {
  margin-left: 3vw;
}

@media (orientation: portrait) {
  .header-container {
    padding: 0 2vw;
  }
  .logo img {
    width: 20vw;
  }
}
.hidden {
  filter: blur(5px);
  opacity: 0;
  transition: 1s;
  transform: translateX(-100%);
}

.show {
  filter: blur(0);
  opacity: 1;
  transform: translateX(0);
}

.hidden-opacity {
  filter: blur(5px);
  opacity: 0;
  transition: 1s;
}

.show-opacity {
  filter: blur(0);
  opacity: 1;
}

.parallax {
  height: 100vh;
  position: relative;
}

#parallax-background {
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/img/parallax-background.png");
}

#parallax-logo {
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 100%;
}

#parallax-logo img {
  width: 100%;
}

#parallax-vectors {
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 100%;
}

#parallax-vectors img {
  width: 100%;
}

.land_title {
  font-family: Open Sans;
  font-weight: 600;
  color: #3c3e72;
  /* font-size: 48px; */
  font-size: calc((100vw - 320px) / (1412 - 320) * (48 - 30) + 30px);
  margin-bottom: 4vw;
  text-transform: uppercase;
}

.land_paragraph + * {
  margin-top: 10px;
}

.land_paragraph {
  font-family: Open Sans;
  font-weight: 400;
  color: #595959;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 12) + 12px);
  text-transform: uppercase;
  line-height: 142%;
}

/* .land_title + * {
    margin-bottom: 1vw;
} */

.about-container {
  display: flex;
  flex-direction: row;
  padding: 75px 0;
  width: 100%;
}

.about-text-container {
  padding: 0 10vw;
  margin: auto;
}

.about-image-container {
  max-width: 50vw;
  transition: 0.8s;
}

.benefits-container {
  background-color: #3c3e72;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 5vw 10vw;
}

.benefits-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 50px;
}

.benefit {
  text-align: center;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.white {
  color: white;
}

.certificates-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vw 10vw;
  text-align: center;
}

.carousel-wrapper .carousel {
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  scroll-behavior: smooth;
  max-width: 70vw;
}

.carousel-wrapper {
  max-width: 1200px;
  position: relative;
}

.carousel img {
  height: 540px;
  object-fit: cover;
  min-width: 350px;
  width: calc(100% / 3);
  margin-left: 3vw;
}

.carousel img:first-child {
  margin-left: 0px;
}

.carousel-arrows {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: fit-content;
  height: fit-content;
  transform: translateY(-50%);
}

.carousel-arrows:first-child {
  left: -50px;
}

.carousel-arrows:last-child {
  right: -50px;
}

.shadow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.none {
  display: none;
}

.disable-scrolling {
  overflow: hidden;
}

.carousel-active-img {
  position: fixed;
  z-index: 101;
  width: fit-content;
  object-fit: cover;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  max-height: 95vh;
  max-width: 95vw;
}

.def-section-padding {
  padding: 5vw 10vw;
}

.about-work-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4vw;
  margin: 0 auto;
  max-width: 626px;
  height: fit-content;
}
.num-container {
  display: flex;
  flex-direction: column;
}

.num-container h1 {
  margin-bottom: 0;
}

.vertical_line {
  width: 1px;
  background-color: #3c3e72;
}
.about-work-wrapper .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5vw;
}

.center {
  text-align: center;
}

.fnt-sz-24 {
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 16) + 16px);
}

.guarantee-container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  gap: 75px;
}

.guarantee-icon {
  object-fit: cover;
}

.guarantee-text {
  font-family: Open Sans;
  font-weight: 400;
  color: #000;
  text-align: left;
}

@media screen and (max-width: 900px) {
  .carousel img {
    width: calc(100% / 2);
  }
}
@media screen and (max-width: 550px) {
  .carousel img {
    width: 100%;
  }
}

@media (orientation: portrait) {
  .about-container {
    flex-direction: column;
    gap: 4vw;
    padding: 0;
  }
  #parallax-logo {
    top: 0px;
  }
  #parallax-vectors {
    top: 0px;
  }
  .parallax {
    height: 30vh;
  }
  .about-text-container {
    max-width: 100%;
    padding: 4vw;
  }
  .about-image-container {
    justify-content: center;
    display: flex;
    margin: 0 auto;
  }
  .benefits-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .about-image-container img {
    object-fit: cover;
    width: 100vw;
  }
  .benefits-container {
    padding: 15vw 10vw;
  }
  .carousel-arrows:first-child {
    left: -20px;
  }
  .carousel-arrows:last-child {
    right: -20px;
  }
  .carousel img {
    height: 340px;
    margin-left: 3vw;
  }
  .guarantee-container {
    flex-direction: column;
    align-items: center;
    gap: 5vw;
  }
  .guarantee-text {
    text-align: center;
  }
}
</style>
