<template>
  <Product v-if="product && product.name" :key="product.id" :product="product">
    <template v-slot:structure="{ inCart, count, addToCart, removeFromCart }">
      <section class="product-page-container">
        <router-link to="/shop">
          <div class="back-to-catalog-wrapper">
            <img src="../assets/svg/Arrow1.svg" alt="" />
            <h1>в каталог</h1>
            <router-link :to="`/cart`" style="margin-left: auto">
              <div id="cartbtn" class="menu-bg catalog-settings">
                <img src="@/assets/svg/basket3-fill.svg" alt="" />
              </div>
            </router-link>
          </div>
        </router-link>
        <div class="product-text-line"></div>
        <div class="product-path">
          <router-link to="/">
            <p>Главная</p>
          </router-link>
          <p>/</p>
          <router-link to="/shop">
            <p>Каталог</p>
          </router-link>
          <p>/</p>
          <p>{{ product.name }}</p>
        </div>
        <div class="product-row">
          <div class="pp-product-image">
            <swiper @swiper="setFirstSwiper" v-if="product.images[0]">
              <swiper-slide v-for="image in product.images" :key="image">
                <img
                  :src="image"
                  :alt="product.name"
                  width="500"
                  height="500"
                />
              </swiper-slide>
            </swiper>
            <img
              v-else
              width="256"
              height="256"
              src="@/assets/img/DONGFENG.jpg"
              :alt="product.name"
            />
            <div
              class="product-image_image-subrow-container"
              v-if="product.images[1]"
            >
              <div
                v-for="(image, index) in product.images"
                :key="image"
              >
                <img
                  @click="firstSwiper.slideTo(index)"
                  :src="image"
                  :alt="product.name"
                  width="120"
                  height="120"
                />
              </div>
            </div>
          </div>
          <div class="product-row_product-text">
            <div class="pp-product-title">
              {{ product.name }}
            </div>
            <div class="pp-product-price">
              {{ beautifyNumber(product.price * 1) }} р
            </div>
            <div class="product-producer">Производитель</div>
            <div class="pp-text">
              <p v-html="product.description"></p>
              <p>Артикул: {{ product.id }}</p>
            </div>
            <div class="price-container">
              <div v-if="inCart" class="card-product-amount-wrapper">
                <div class="amount-container">
                  <div
                    @click="removeFromCart(product)"
                    class="card-amount-icons card-amount-minus"
                  >
                    <img src="@/assets/img/math-minus.svg" alt="" />
                  </div>
                  <div class="amount-paragraph product-price">{{ count }}</div>
                  <div
                    @click="addToCart(product)"
                    class="card-amount-icons card-amount-plus"
                  >
                    <img src="@/assets/img/math-plus.svg" alt="" />
                  </div>
                </div>
                <router-link to="/cart">
                  <h1 class="in-cart-btn">В корзине</h1>
                </router-link>
              </div>
              <div
                v-else
                @click="addToCart(product)"
                class="no-select pp-add-to-cart-btn"
              >
                Добавить в корзину
              </div>
            </div>
          </div>
          <!-- <div class="short-description">
            <div class="pp-title">Коротко о товаре</div>
            
          </div> -->
        </div>
        <div class="product-text-line"></div>
        <div class="product-suggestion-block">
          <h1 class="product-suggestion-block_title">
            вам так же может понравиться
          </h1>
          <div class="suggestion-row">
            <div v-for="product in this.products.filter((product) => product.id != this.product.id)" :key="product.id" class="suggest-product-card">
              <div class="suggest-product-card_image-block">
                <img v-if="product.images[0]" :src="product.images[0]">
                <img v-else src="../assets/img/DONGFENG2.jpg" alt="" />
              </div>
              <router-link v-if="!product.empty" :to="`/product/${product.id}`">
                <h1 class="suggest-product-card_title">{{ product.name }}</h1>
              </router-link>
              <h1 class="suggest-product-card_price">{{ beautifyNumber(product.price * 1) }} р</h1>
            </div>
          </div>
        </div>
        <div class="product-text-line"></div>
        <!-- <div class="horizontal-line"></div> -->
        <!-- <div class="product-row2">
          <div class="product-description">
            <div class="pp-title">Описание</div>
            <div class="pp-text">
              <p v-html="product.description"></p>
              <p>Артикул: {{ product.id }}</p>
            </div>
          </div>
          <div
            style="display: none; filter: blur(2px)"
            class="product-characteristics"
          >
            <div class="pp-title">Подробные характеристики</div>
            <div class="text-container">
              <div class="label">
                <p>Тип</p>
                <p>Назначение</p>
                <p>Мощность</p>
                <p>Рабочий объем</p>
                <p>Количество цилиндров</p>
                <p>Количество тактов</p>
                <p>Емкость топливного бака</p>
                <p>Диаметр вала</p>
                <p>Положение вала</p>
                <p>Объем масла</p>
                <p>Тип охлаждения</p>
                <p>Расход топлива, г/кВт*ч</p>
                <p>Вес</p>
              </div>
              <div class="value">
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
                <p>значение</p>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="horizontal-line"></div> -->
        <p style="display: none; filter: blur(2px)" class="pp-title">
          Ранее просмотренные товары
        </p>
        <div
          style="display: none; filter: blur(2px)"
          class="early-seen-products"
        >
        
          <div class="catalog-product-card-wrapper">
            <div class="catalog-product-card">
              <div class="product-card-img">
                <img src="assets/img/products/product1.png" alt="" />
              </div>
              <div class="manufacturer">Производитель</div>
              <div class="product-title">
                product
              </div>
              <div class="product-price">1 999 ₽</div>
              <div id="add-to-cart-btn" class="subheader-cart-btn">
                В корзину
              </div>
            </div>
          </div>
          <div class="catalog-product-card-wrapper">
            <div class="catalog-product-card">
              <div class="product-card-img">
                <img src="assets/img/products/product2.png" alt="" />
              </div>
              <div class="manufacturer">Производитель</div>
              <div class="product-title">
                Двигатель АИР80B2 IE1 2.2кВт 3000об/мин
              </div>
              <div class="product-price">1 999 ₽</div>
              <div class="card-product-amount-wrapper">
                <div class="amount-container">
                  <div class="card-amount-icons">
                    <img src="assets/img/math-minus.svg" alt="" />
                  </div>
                  <div class="amount-paragraph product-price">1</div>
                  <div class="card-amount-icons">
                    <img src="assets/img/math-plus.svg" alt="" />
                  </div>
                </div>
                <router-link to="/cart">
                  <h1 class="in-cart-btn">В корзине</h1>
                </router-link>
              </div>
            </div>
          </div>
          <div class="catalog-product-card-wrapper">
            <div class="catalog-product-card">
              <div class="product-card-img">
                <img src="assets/img/products/product1.png" alt="" />
              </div>
              <div class="manufacturer">Производитель</div>
              <div class="product-title">
                Двигатель kia sportage g4kd / mitsubishi 4b11
              </div>
              <div class="product-price">1 999 ₽</div>
              <div id="add-to-cart-btn" class="subheader-cart-btn">
                В корзину
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Product>
</template>

<script>
import Product from "@/components/Shop/ProductComponent.vue";
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapActions,mapGetters } from "vuex";

import axios from "axios";
export default {
  setup() {
    const firstSwiper = ref(null);
    const setFirstSwiper = (swiper) => {
      firstSwiper.value = swiper;
    };
    return {
      firstSwiper,
      setFirstSwiper,
    };
  },
  components: {
    Product,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      product: {},
      mySwiper: null,
    };
  },
  computed: {
    ...mapGetters("products", ["products"])
  },
  methods: {
    ...mapActions("products", ["loadProductsBySection"]),
    async getProductById(productId) {
      try {
        const response = await axios.get(
          "https://trade-orion.ru/profile/handlers/getProduct.php",
          { params: { id: productId } }
        );
        const { data } = response;
        console.log(data);
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    beautifyNumber(number) {
      const formattedNumber = number;
      return formattedNumber.toLocaleString("en-US").replaceAll(",", " ");
    },
  },
  async mounted() {
    const productId = this.$route.params.id;
    this.product = await this.getProductById(productId);
    this.loadProductsBySection(this.product.sections);
    console.log(this.product);
    console.log(this.products);
  },

  name: "ProductView",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro&family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
.swiper {
  background: #f6f6f6;
}
.product-page-container {
  padding: 0vw 10vw;
  display: flex;
  flex-direction: column;
  margin-top: 6.7vw;
}
.product-text-line {
  width: 100%;
  height: 0.069vw;
  background-color: #433b64;
  margin-bottom: 4.7vw;
}
.back-to-catalog-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3.47vw;
  margin-bottom: 2.5vw;
}

.back-to-catalog-wrapper h1 {
  color: #433b64;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (32 - 7.11) + 7.11px);
  font-style: normal;
  font-weight: 700;
  line-height: 62.5%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.back-to-catalog-wrapper img {
  height: 1.66vw;
}

.product-path {
  display: flex;
  flex-direction: row;
  width: inherit;
  overflow-x: hidden;
  gap: 5px;
  white-space: nowrap;
  margin-bottom: 1.59vw;
}

.product-path p {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (12 - 2.6) + 2.6px);
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.42px;
  text-transform: uppercase;
}

.product-path p:nth-child(odd) {
  cursor: pointer;
}
.product-row_product-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 3vw;
  padding-top: 2.08vw;
  margin-bottom: auto;
}
.pp-product-title {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (24 - 5.3) + 5.3px);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.25vw;
  text-transform: uppercase;
  margin-bottom: 1.52vw;
  line-height: 135%;
  max-width: 80%;
}

.pp-product-price {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (24 - 5.3) + 5.3px);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 2.08vw;
  letter-spacing: 0.25vw;
}
.product-price {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 9) + 9px);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1.95px;
  text-align: center;
  margin-top: 0;
  border-radius: 3px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.17) inset;
  border: none;
}

.pp-add-to-cart-btn {
  width: 19.02vw;
  /* max-width: 274px; */
  border-radius: 3px;
  background: #433b64;
  height: 3.125vw;
  /* max-height: 45px; */
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 3.5) + 3.5px);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.183vw;
  white-space: nowrap;
  text-transform: uppercase;
}

.in-cart-btn {
  display: none;
}
.card-product-amount-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  /* height: 37px; */
  justify-content: center;
  margin-top: unset;
}
.card-amount-icons {
  display: flex;
  align-items: center;
  width: 2.56vw;
  width: 2.56vw;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 3px;
  background: #fff;
  box-shadow: none;
}
.product-producer {
  color: #6a6a6a;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 3.33) + 3.33px);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.078vw;
  margin-bottom: 2.36vw;
  text-transform: lowercase;
}

.pp-text {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (14 - 3.11) + 3.311px);
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: 0.072vw;
  max-width: 80%;
  margin-bottom: 5.48vw;
}
.pp-text p + p {
  margin-top: 0.347vw;
}

.product-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6.25vw;
}

.product-row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.pp-product-image {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.swiper {
  object-fit: cover;
  width: 34.7vw;
  height: 34.7vw;
  background-color: white;
  overflow: hidden;
  border-radius: 2%;
}

.swiper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.product-image_image-subrow-container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: flex-start;

  height: 9.72vw;
  /* max-height: 140px; */
  margin-top: 1.59vw;
  gap: 1.041vw;
  width: 27.08vw;
  /* max-width: 390px; */
  overflow: scroll;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #433b64;
}

::-webkit-scrollbar {
  width: 100%;
  height: 0.48vw;
}
::-webkit-scrollbar-thumb {
  background-color: #433b64;
  border-radius: 0.48vw;
}
.product-image_image-subrow-container div {
  min-width: 8.3vw;
  height: 8.3vw;
  background-color: #d9d9d9;
  transition: all 0.7s;
}
.product-image_image-subrow-container div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* html {
  transition: all 2s;
}
html:hover {
  filter: invert(1);
  filter: hue-rotate(120deg);
  transform: rotateZ(5deg);
  transition: all 2s;
} */
.product-image_image-subrow-container div:hover {
  transform: scale(105%);
  transition: all 0.7s;
  cursor: pointer;
}
.short-description {
  max-width: 330px;
}

.pp-title {
  font-weight: 700;
  font-size: 20px;
  color: #04080f;
  font-family: Open Sans;
  margin-bottom: 4px;
}

.text-container .label,
.label,
.value {
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1100 - 320) * (20 - 15) + 15px);
  color: #495057;
  font-family: Open Sans;
}

.price-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 19vw;
}

.oformlenie-btn {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 16px;
  color: #495057;
  text-decoration: underline;
}

.product-description {
  max-width: 360px;
}

.product-characteristics {
  display: flex;
  flex-direction: column;
  width: 490px;
}

.text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #adb5bd;
  margin-bottom: 25px;
}

.early-seen-products {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 100px;
}
.product-suggestion-block {
  margin-bottom: 9.44vw;
}
.product-suggestion-block_title {
  color: #433b64;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 3.5) + 3.5px);
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: 0.05vw;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2.91vw;
}
.suggestion-row {
  display: grid;
  grid-template-columns: repeat(4, 13.88vw);
  gap: 20px;
  justify-content: center;
}
.suggest-product-card {
  display: flex;
  flex-direction: column;
  width: 13.88vw;
}
.suggest-product-card_image-block {
  width: 13.88vw;
  height: 13.88vw;
  background-color: #d9d9d9;
  margin-bottom: 1.66vw;
}
.suggest-product-card_image-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.suggest-product-card_title {
  color: #000;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (20 - 4.44) + 4.44px);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.6px;
  margin-bottom: 1px;
}
.suggest-product-card_subtitle {
  color: #555;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (14 - 3.11) + 3.11px);
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: 1.12px;
  margin-bottom: 0.833vw;
}
.suggest-product-card_price {
  color: #000;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 3.5) + 3.5px);
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 1.28px;
}

@media (max-width: 960px) {
  .product-path p {
    font-size: 9px;
  }
  .pp-product-title {
    font-size: 15px;
  }
  .pp-product-price {
    font-size: 15px;
  }
  .product-producer {
    font-size: 10px;
  }
  .pp-text {
    font-size: 11px;
  }
  .swiper {
    width: 250px;
    height: 250px;
  }
  .product-image_image-subrow-container {
    min-width: 250px;
    min-height: 90px;
    gap: 5px;
  }
  .product-image_image-subrow-container div {
    min-width: 80px;
    min-height: 80px;
  }
  .back-to-catalog-wrapper h1 {
    font-size: 14px;
  }
  .back-to-catalog-wrapper img {
    height: 14px;
  }
  .pp-add-to-cart-btn {
    font-size: 9.5px;
    width: 200px;
    height: 30px;
  }
  .product-suggestion-block_title {
    font-size: 13px;
  }
  .suggest-product-card_title {
    font-size: 13px;
  }
  .suggest-product-card_subtitle {
    font-size: 9px;
  }
  .suggest-product-card_price {
    font-size: 11px;
  }
}
@media (max-width: 550px) {
  .product-page-container {
    padding: 0 5vw;
  }
  .product-row {
    flex-direction: column;
  }
  .pp-product-image {
    margin: 0 auto;
  }
  .product-path {
    padding-left: 3vw;
  }
  .suggestion-row {
    grid-template-columns: repeat(3, 20.88vw);
  }
  .suggest-product-card_image-block {
    width: 20.88vw;
    height: 20.88vw;
  }
  .suggest-product-card {
    width: 20.88vw;
    height: fit-content;
  }
  .suggest-product-card:nth-child(4) {
    display: none;
  }
  .product-suggestion-block {
    margin-bottom: 4.44vw;
  }

  /* .pp-product-title {
    font-size: 13px;
  }
  .pp-product-price {
    font-size: 13px;
  }
  .product-producer {
    font-size: 8px;
  }
  .pp-text {
    font-size: 9px;
  } */
  .pp-add-to-cart-btn {
    /* font-size: 7.5px; */
    width: 150px;
    height: 20px;
  }
}
/* @media (min-width: 1440px) {
  .back-to-catalog-wrapper {
    gap: 50px;
    margin-bottom: 36px;
  }
  .back-to-catalog-wrapper img {
    height: 24px;
  }
  .product-text-line {
    height: 1px;
  }
  .product-page-container {
    margin-top: 97px;
  }
  .product-text-line {
    margin-bottom: 68px;
  }
  .product-row {
    margin-bottom: 90px;
  }
  .swiper {
    width: 500px;
    height: 500px;
  }
  .product-image_image-subrow-container {
    margin-top: 23px;
    gap: 15px;
  }
  .product-image_image-subrow-container div {
    min-width: 120px;
    height: 120px;
  }
  .product-path {
    margin-bottom: 23px;
  }
  .product-path p {
    font-size: 12px;
  }
  ::-webkit-scrollbar {
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #433b64;
    border-radius: 7px;
  }
  .product-row_product-text {
    padding-left: 44px;
    padding-top: 30px;
  }
  .pp-product-title {
    margin-bottom: 22px;
    letter-spacing: 3.72px;
    max-width: 550px;
  }
  .pp-product-price {
    font-size: 24px;
    margin-bottom: 30px;
    letter-spacing: 3.72px;
  }
  .pp-add-to-cart-btn {
    font-size: 16px;
    letter-spacing: 2.64px;
  }
  .product-producer {
    font-size: 15px;

    letter-spacing: 1.125px;
    margin-bottom: 34px;
  }
  .pp-text {
    font-size: 14px;
    letter-spacing: 1.05px;
    max-width: 276px;
    margin-bottom: 79px;
  }
  .pp-text p + p {
    margin-top: 5px;
  }
  .back-to-catalog-wrapper h1 {
    font-size: 32px;
  }
  .product-suggestion-block_title {
    font-size: 16px;
    letter-spacing: 0.75px;
    margin-bottom: 42px;
  }
  .suggest-product-card_image-block {
    margin-bottom: 24px;
  }
  .suggest-product-card_title {
    font-size: 20px;
  }
  .suggest-product-card_subtitle {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .suggest-product-card_price {
    font-size: 16px;
  }
  .product-suggestion-block {
    margin-bottom: 136px;
  }
} */

/* @media (orientation: portrait) {
  .product-row {
    flex-direction: column;
    gap: 15px;
  }
  .product-row2 {
    flex-direction: column;
    gap: 5vh;
  }
  .early-seen-products {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 100px;
    max-width: 100vw;
  }
  .product-characteristics {
    display: flex;
    flex-direction: column;
    width: unset;
  }
  .product-image {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 320px) {
  .pp-product-image {
    width: 100%;
    height: 100%;
  }
} */
@media (max-width: 480px) {
  .price-container {
    max-width: 160px;
  }
}
</style>
