<template>
	<slot :addToCart="addToCart" :removeFromCart="removeFromCart" :inCart="inCart" :count="count" name="structure"></slot>
</template>
  
<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inCart() {
      return this.$store.state.cart.cart.some((p) => p.id === this.product.id);
    },
    count() {
      const productInCart = this.$store.state.cart.cart.find(
        (p) => p.id === this.product.id
      );
      return productInCart ? productInCart.count : 0;
    },
  },
  methods: {
    addToCart(product) {
      this.$store.commit('cart/addToCart', product);
    },
    removeFromCart(product) {
      this.$store.commit('cart/removeFromCart', product);
    },
  },
};
</script>
  
<style></style>
  