<template>
  <div>
    <h1>Closed</h1>
  </div>
  <!-- <div class="content-wrapper">
    <div v-if="loading" class="loader-overlay">
      <div class="loader"></div>
      <div class="loading-text">
        Идет выполнение запроса. Пожалуйста, подождите...
      </div>
    </div>
    <div class="search-wrapper">
      <div class="catalog-filters-title select-none">Сортировка:</div>
      Сортировка
      <select class="filter-sel" v-model="sortBy" @change="applySort">
        <option value="price">От меньшей цены</option>
        <option value="priceReverse">От большей цены</option>
      </select>
      <input v-model="password" type="password" placeholder="Введите пароль" />
      <input v-model="searchQuery" placeholder="Введите запрос" />

      <button @click="searchProducts">Найти товары</button>
      <input
        class="input-image-file"
        type="file"
        @change="handleImageUpload"
        accept="image/*"
      />
      <button @click="searchByImage">Поиск по картинке</button>
      <button @click="toggleViewMode">
        {{ gridView ? "Переключить в таблицу" : "Переключить в сетку" }}
      </button>
      <img
        :src="'data:image/webp;base64,' + selectedImage"
        alt="Selected Image"
        v-if="selectedImage"
      />
    </div> -->
  <!-- Вывод товаров -->
  <!-- <div v-if="products.length">
      <div class="catalog-content-box">
        <div v-if="gridView" class="catalog-product-grid">
          <div
            v-for="product in filteredProducts"
            :key="product.detailUrl"
            class="catalog-product-card-wrapper"
          >
            <div class="catalog-product-card">
              <div class="product-card-img">
                <img :src="product.imageUrl" alt="Product Image" />
              </div>
              <h3 class="product-title">{{ product.subject }}</h3>
              <p class="product-title">{{ product.brief }}</p>
              <p class="product-title">Цена: {{ product.price }}</p>
              <a class="product-url" :href="product.detailUrl" target="_blank"
                >Подробнее</a
              >
            </div>
          </div>
        </div>
        <table v-else>
          <thead>
            <tr>
              <th>Название</th>
              <th>Бренд</th>
              <th>Производство</th>
              <th>Цена</th>
              <th>Купить</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in filteredProducts" :key="product.detailUrl">
              <td>{{ product.subject }}</td>
              <td>{{ getProductAttribute(product.brief, "品牌:") }}</td>
              <td>{{ getProductAttribute(product.brief, "产地:") }}</td>
              <td>{{ product.price }}</td>
              <td>
                <a class="product-url" :href="product.detailUrl" target="_blank"
                  >Подробнее</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->

  <!-- Ошибка -->
  <!-- <div v-if="error">{{ error }}</div>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      products: [],
      error: null,
      selectedImage: null,
      gridView: true,
      newKeyword: "",
      keywords: [], // Список добавленных слов
      loading: false, // Переменная состояния для лоадера
      sortBy: "price",
      password: "",
    };
  },
  computed: {
    filteredProducts() {
      let filtered = [...this.products];
      // Сортировка
      if (this.sortBy === "price") {
        filtered.sort((a, b) => a.price - b.price);
      } else if (this.sortBy === "priceReverse") {
        filtered.sort((a, b) => b.price - a.price);
      }

      return filtered;
    },
  },
  methods: {
    async addKeyword() {
      this.error = null;
      this.loading = true; // Показать лоадер

      if (this.newKeyword.trim() === "") {
        this.loading = false; // Скрыть лоадер
        return; // Игнорировать пустые слова
      }

      try {
        const response = await fetch("https://cors.trade-orion.ru/add", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: this.newKeyword.trim(),
            username: this.password,
            password: this.password,
          }),
        });

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        this.products = data.objects;
        this.keywords.push(this.newKeyword); // Добавить слово в список
        this.newKeyword = ""; // Очистить поле ввода
      } catch (error) {
        this.error = "Произошла ошибка при выполнении запроса";
      } finally {
        this.loading = false; // Скрыть лоадер после завершения запроса
      }
    },

    async deleteKeyword(keyword) {
      this.error = null;
      this.loading = true; // Показать лоадер

      try {
        const response = await fetch("https://cors.trade-orion.ru/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: keyword,
            username: this.password,
            password: this.password,
          }),
        });

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        this.products = data.objects;
        this.keywords = this.keywords.filter((kw) => kw !== keyword); // Удалить слово из списка
      } catch (error) {
        this.error = "Произошла ошибка при выполнении запроса";
      } finally {
        this.loading = false; // Скрыть лоадер после завершения запроса
      }
    },
    async searchProducts() {
      this.error = null;
      this.loading = true; // Показать лоадер

      try {
        const response = await fetch("https://cors.trade-orion.ru/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "keywords",
            data: this.searchQuery,
            username: this.password,
            password: this.password,
          }),
        });

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        this.products = data.objects;
      } catch (error) {
        this.error = "Произошла ошибка при выполнении запроса";
      } finally {
        this.loading = false; // Скрыть лоадер после завершения запроса
      }
    },

    async searchByImage() {
      this.error = null;
      this.loading = true; // Показать лоадер

      if (!this.selectedImage) {
        this.error = "Загрузите изображение";
        return;
      }

      try {
        const response = await fetch("https://cors.trade-orion.ru/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "image",
            data: this.selectedImage, // Здесь должно быть изображение в формате base64
            username: this.password,
            password: this.password,
          }),
        });

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        this.products = data.objects;
      } catch (error) {
        this.error = "Произошла ошибка при выполнении запроса";
      } finally {
        this.loading = false; // Скрыть лоадер после завершения запроса
      }
    },

    handleImageUpload(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.selectedImage = e.target.result.split(",")[1]; // Получаем base64 изображения без заголовка
        };
        reader.readAsDataURL(file);
      }
    },

    toggleViewMode() {
      this.gridView = !this.gridView;
    },

    getProductAttribute(text, attribute) {
      const startIndex = text.indexOf(attribute);
      if (startIndex !== -1) {
        const endIndex = text.indexOf(" ", startIndex + attribute.length);
        if (endIndex !== -1) {
          return text.slice(startIndex + attribute.length, endIndex);
        }
      }
      return "N/A";
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
}

div {
  background-color: #000000;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: white;
}

.catalog-product-card {
  max-height: fit-content;
}
.product-title {
  max-height: fit-content;
  height: 3.47vw;
}
.product-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  width: 200px;
}

.search-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 0 2.7vw;
  flex-direction: column;
}

.sortselect-title {
  font-family: Inter;
  font-weight: 500;
  color: #3c3e72;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 14) + 14px);
  margin-bottom: 5px;
}

.search-wrapper img {
  display: none;
}

.product-url {
  font-family: Inter;
  text-decoration: underline;
  color: #000000;
  font-size: smaller;
}

button {
  border-radius: 0.416vw;
  background-color: #3c3e72;
  color: white;
  padding: 0 1vw;
  border: none;
  max-width: fit-content;
  font-weight: 400;
  font-family: Inter;
  letter-spacing: 0.036vw;
}

.input-image-file {
  padding: 0.5vw 10px;
}

input {
  width: fit-content;
  border: 0.0694vw solid #3c3e72;
  border-right-width: 0.0694vw;
  border-right-style: solid;
  border-right-color: rgb(60, 62, 114);
  border-radius: 0.416vw;

  padding: 0px 0px 0px 10px;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 12) + 12px);
  color: rgba(0, 0, 0, 0.52);
  font-weight: 400;
  font-family: Inter;
  box-sizing: content-box;
  outline: none;
  letter-spacing: 0.036vw;
  height: 2.7vw;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3c3e72;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loading-text {
  color: white;
  margin-top: 20px;
  font-family: Inter;
}

th {
  font-family: "Inter";
  font-weight: 600;
  border-bottom: 1px solid #3c3e72;
  padding: 10px;
  color: #32334b;
}

td {
  padding: 10px;
  border-bottom: 1px solid #3c3e72;
  border-right: 1px solid #3c3e72;
  font-family: "Inter";
}
tr:nth-child(2n) {
  background-color: #dbdae1;
}
tr:nth-child(2n + 1) {
  background-color: #e6e8f5;
}

.search-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
button {
  height: 2.7vw;
  border-radius: 5px;
  padding: 0 15px;
}
.filter-sel {
  max-width: fit-content;
}

.keyword-wrapper {
  font-weight: 400;
  font-family: Inter;
  background-color: #606288;
  padding-left: 10px;
  color: white;
  border-radius: 5px;
}

@media (max-width: 1300px) {
  input {
    height: fit-content;
    min-height: 35px;
    border-radius: 5px;
  }
  .input-image-file {
    padding: 6px 10px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
