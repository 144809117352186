<template>
  <div id="app">
    <!-- общая разметка приложения -->
    <HeaderComponent />
    <router-view :key='$route.fullPath'/>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
export default {
  components: {
    HeaderComponent,
  },
  created() {
    this.$store.dispatch("cart/loadCart");
  },
  name: "App",
  // Дополнительная логика или методы
};
</script>

<style>
body {
  width: 100vw;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}
a {
  color: white;
  text-decoration: none;
}
img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* pointer-events: none; */
}
.info-block-product-title::-webkit-scrollbar {
  display: none;
}
.no-select {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.back-to-catalog-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3.47vw;
  margin-bottom: 2.5vw;
}

.back-to-catalog-wrapper h1 {
  color: #433b64;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (32 - 7.11) + 7.11px);
  font-style: normal;
  font-weight: 700;
  line-height: 62.5%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.back-to-catalog-wrapper img {
  height: 1.66vw;
}
</style>
