<template>
  <section>
    <div class="container" style="margin-top: 48px">
      <div class="adress-card" style="height: fit-content">
        <div class="card__content dostavka">
          <div class="card__content__title">Качество</div>
          <div class="card__line"></div>
          <div class="card__content__row">
            <div class="card__content__subtitle">
              <span class="card__content__text">
                <div
                  style="margin-top: 13px; margin-bottom: 13px"
                  class="card__content__title"
                >
                  Упаковка
                </div>
                <ol>
                  <li>
                    Товар отгружается в специальной упаковке, соответствующей
                    характеру поставляемого товара.
                  </li>
                  <li>
                    Упаковка товара обеспечивает сохранность товара во время
                    транспортировки и перегрузки и предохранять его от
                    повреждений и коррозии.
                  </li>
                  <li>
                    Мы несем ответственность перед Покупателем за порчу товара
                    вследствие некачественной или ненадлежащей консервации и
                    упаковки, а также за убытки, вызванные отправкой товара не
                    по адресу вследствие неполной или неправильной маркировки.
                  </li>
                </ol>
                <div
                  style="margin-top: 13px; margin-bottom: 13px"
                  class="card__content__title"
                >
                  Гарантии
                </div>
                <ol>
                  <li>
                    Мы гарантируем, что характеристики поставляемого товара
                    соответствуют техническим требованиям на данный товар,
                    согласованным с Покупателем.
                  </li>
                  <li>
                    Техническая документация на товар предоставляется объеме,
                    необходимом для оценки и подтверждения качества продукции.
                  </li>
                  <li>
                    Мы гарантируем предоставление всей документации,
                    подтверждающей соответствие товара по качеству.
                  </li>
                  <li>
                    Мы предоставляем документацию о прохождении товаром границ
                    РФ в соответствии с таможенными требованиями
                    законодательства РФ.
                  </li>
                  <li>
                    Мы можем предоставить фото-видеоматериалы процесса
                    изготовления товара (при необходимости), чтобы подтвердить
                    соответствие технической документации и гарантировать
                    получение Товара соответствующего качества. По запросу
                    Покупателя, мы гарантируем предоставление документации о
                    процессе изготовления товара.
                  </li>
                </ol></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <center
      class="card__content__text card__content__subtitle"
      style="margin-bottom: 2vh"
    >
      <p>Общество с ограниченной ответственностью «ОРИОН-ТРЕЙД»</p>
    </center>
  </section>
</template>

<script>
export default {
  name: "ContactsView",
};
</script>

<style>
.header_line {
  margin-bottom: 50px;
}
.container {
  margin: 0 auto;
}
.adress-card {
  height: fit-content;
  width: 70vw;
  background: #fff;
  border-radius: 5px;
  padding: 25px 30px;
  display: flex;
  margin: 0 auto;
}

.adress-card + * {
  margin-top: 30px;
}

.card__content {
  max-width: 300px;
  margin-right: 20px;
}

.card__content__title {
  font-family: Open Sans;
  font-weight: 600;
  color: #3c3e72;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 143%;
}

.card__line {
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin: 10px 0px;
}

.card__content__row {
  display: inline;
  margin-bottom: 10px;
}

.card__content__row img {
  margin-right: 12px;
  vertical-align: middle;
}

.card__content__subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 152%;
  color: #3c3e72;
}
.card__content__text {
  color: #13293d;
}

.card__vidjet {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  width: 70%;
  height: 100%;
  margin: 0 auto;
}

.dostavka {
  max-width: fit-content;
}

@media (orientation: portrait) {
  .adress-card {
    flex-direction: column;
    margin: 0 auto;
    width: 90vw;
    margin-bottom: 30px;
    gap: 20px;
  }
  .card__content__title {
    font-size: 2.5vh;
  }
  .card__content__subtitle {
    font-size: 15px;
    line-height: 143%;
  }
  .card__vidjet {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
  }
  .card__content {
    height: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1440px) {
  .card__content__title {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (35.5 - 20) + 20px);
  }
  .card__content__subtitle {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (28.4 - 16) + 16px);
  }
  .card__content {
    max-width: unset;
  }
}
</style>
