<template>
  <div class="catalog-menu-container">
    <h1>Категории</h1>
    <div class="category-line"></div>
    <div
      v-for="section in sections"
      :key="section.section_id"
      class="category-container"
    >
      <h1
        @click="setsection(section.section_id, $event)"
        class="category-title select-none"
      >
        {{ section.section_name }}
        <img
          v-if="section.subsections.length > 0"
          class="category-title-arrow"
          src="@/assets/img/arrow-down.svg"
          alt=""
        />
      </h1>
      <div v-if="section.subsections.length > 0" class="subcategory-box">
        <h1
          v-for="subsection in section.subsections"
          :key="subsection.section_id"
          @click="setsection(subsection.section_id, $event)"
          class="subcategory-title select-none"
        >
          {{ subsection.section_name }}
        </h1>
      </div>
    </div>
  </div>
</template>
<style>
.catalog-menu-container > h1 {
  color: #433b64;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}
.category-line {
  width: 100%;
  height: 1px;
  border-bottom: 0.069vw solid #433b64;
  margin-bottom: 2vw;
  margin-top: -5px;
}
@media (orientation: landscape) {
  .filter-menu-active {
    height: 60vh;
  }
}
@media (orientation: portrait) {
  .filter-menu-active {
    height: 70vh;
  }
}
</style>
<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      sections: [],
    };
  },

  mounted() {
    this.sectionsInit();
  },

  created() {
    axios
      .get("https://trade-orion.ru/profile/handlers/getsections.php")
      .then((response) => {
        const { data } = response;
        this.sections = this.transformArray(data);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    ...mapActions("products", ["loadProductsBySection"]),
    sectionsInit() {
      const filterBtn = document.querySelector("#filter-btn");
      const filterMenu = document.querySelector(".catalog-filters-container");
      const body = document.querySelector("body");

      filterBtn.addEventListener("click", () => {
        const categoriesMenu = document.querySelector(
          ".catalog-menu-container"
        );
        if (categoriesMenu.matches(".filter-menu-active")) {
          categoriesMenu.classList.remove("filter-menu-active");
          body.classList.toggle("overflow-hidden");
        }
        filterMenu.classList.toggle("filter-menu-active");
        body.classList.toggle("overflow-hidden");
      });

      const categoryContainers = document.querySelectorAll(
        ".category-container"
      );
      categoryContainers.forEach((container) => {
        const title = container.querySelector(".category-title");
        const categoryTitleArrow = title.querySelector(".category-title-arrow");
        const subcategoryBox = container.querySelector(".subcategory-box");

        categoryTitleArrow.addEventListener("click", (event) => {
          console.log(event);
          categoryTitleArrow.classList.toggle("category-title-arrow-active");
          subcategoryBox.classList.toggle("subcategory-box-active");
        });
      });

      const subcategoryTitles = document.querySelectorAll(".subcategory-title");
      subcategoryTitles.forEach((title) => {
        title.addEventListener("click", (event) => {
          subcategoryTitles.forEach((title) => {
            title.classList.remove("subcategory-title-active");
          });
          event.currentTarget.classList.toggle("subcategory-title-active");
        });
      });

      const categoryBtn = document.querySelector("#CategoryBtn");
      const categoriesMenu = document.querySelector(".catalog-menu-container");
      categoryBtn.addEventListener("click", () => {
        if (filterMenu.matches(".filter-menu-active")) {
          filterMenu.classList.remove("filter-menu-active");
          body.classList.toggle("overflow-hidden");
        }
        categoriesMenu.classList.toggle("filter-menu-active");
        body.classList.toggle("overflow-hidden");
      });
    },
    transformArray(array) {
      const sections = {};

      // Сначала пройдемся по всем элементам и сгруппируем их по section_id
      array.forEach((item) => {
        sections[item.section_id] = item;
        sections[item.section_id].subsections = [];
      });

      // Затем пройдемся по всем элементам еще раз и добавим их в соответствующие родительские объекты
      array.forEach((item) => {
        if (item.section_pod) {
          sections[item.section_pod].subsections.push(item);
        }
      });

      // Наконец, вернем только корневые объекты, т.е. те, у которых section_pod пустой
      return Object.values(sections).filter((item) => !item.section_pod);
    },

    setsection(section_id, event) {
      if (event.target.classList.contains("category-title-arrow")) {
        event.target.classList.toggle("category-title-arrow-active");
        event.target.parentElement.parentElement
          .querySelector(".subcategory-box")
          .classList.toggle("subcategory-box-active");
      } else {
        var CategoriesMenu = document.querySelector(".catalog-menu-container");
        var body = document.querySelector("body");
        CategoriesMenu.classList.remove("filter-menu-active");
        body.classList.toggle("overflow-hidden");
        this.loadProductsBySection(section_id);
      }
    },
  },
};
</script>
