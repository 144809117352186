// cart.js
import Cookies from 'js-cookie';

export default {
    namespaced: true,
    state: {
        cart: []
    },
    getters: {
        totalCartPrice(state) {
            return state.cart.reduce((total, product) => {
                return total + (product.price * product.count);
            }, 0);
        }
    },
    mutations: {
        setCart(state, cart) {
            state.cart = cart;
        },
        addToCart(state, product) {
            const existingProduct = state.cart.find(p => p.id === product.id);
            if (existingProduct) {
                existingProduct.count++;
            } else {
                product.count = 1;
                state.cart.push(product);
            }
            this.dispatch('cart/saveCart');
        },
        removeFromCart(state, product) {
            const index = state.cart.findIndex((p) => p.id === product.id);
            if (index !== -1 && state.cart[index].count > 1) {
                state.cart[index].count--;
            } else if (index !== -1) {
                state.cart.splice(index, 1);
            }
            this.dispatch('cart/saveCart');
        }
    },
    actions: {
        loadCart({ commit }) {
            const cart = Cookies.get('cart');
            if (cart) {
                commit('setCart', JSON.parse(cart));
            }
        },
        saveCart({ state }) {
            Cookies.set('cart', JSON.stringify(state.cart));
        }
    }
}