<template>
  <section class="feedback-section">
    <RouterLink to="/cart">
      <h2 class="back-to-cart-btn">
        <img src="@/assets/svg/arrow-left.svg" alt="<" />
        Обратно к корзине
      </h2>
    </RouterLink>
    <div class="osel">
      <div>
        <h1 class="thanks-text">
          Спасибо за ваш заказ, в скором времени с вами свяжутся, чтобы обсудить
          детали оплаты и доставки
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  mounted() {},
  components: {},
  data() {
    return {};
  },
  computed: {
    cartItems() {
      return this.$store.state.cart.cart;
    },
    totalCartPrice() {
      return this.$store.getters["cart/totalCartPrice"];
    },
    isFormValid() {
      return this.name && this.phone && this.email && this.address;
    },
    ...mapGetters("cart", ["totalCartPrice"]),
  },
  methods: {},
  name: "ThanksView",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");
.strangeDiv {
  width: 100%;
}
.strangeDiv.active {
  width: 50% !important;
}
.feedback-section {
  padding: 5.625vw 10vw;
  gap: 25px;
  display: flex;
  flex-direction: column;
}

@media (orientation: landscape) {
  .osel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.final-cost {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 14) + 14px);
  color: #3c3e72;
  border-bottom: 2px solid #3c3e72;
  align-self: baseline;
}

.feedback-section > h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 12) + 12px);
  color: #3c3e72;
}

/* .feedback-section > div > div > h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 12) + 12px);
  color: #3c3e72;
} */

.back-to-cart-btn {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 10) + 10px);
  color: #3c3e72;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
  white-space: nowrap;
  transition: 0.1s;
  box-sizing: border-box;
  line-height: 100%;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
}

.back-to-cart-btn:hover {
  border-bottom: 1px solid #3c3e72;
  transition: 0.1s;
}

.feedback-blocks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.feedback-name-number {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.input-label {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.input-label h1 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 13) + 13px);
  color: #495057;
  margin-bottom: 5px;
}

.input-label input,
textarea {
  outline: none;
  width: 100%;
  border: 2px solid #2d2d3e;
  border-radius: 5px;
  align-items: center;
  padding: 10px;
}
#input-adress {
  width: 100%;
  box-sizing: border-box;
}

.input-label input::placeholder,
.input-label input,
textarea {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 10) + 10px);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.2px;
  border: 1px solid #848484;
  border-radius: 0;
}
.input-label input::placeholder {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 10) + 10px);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.2px;
  border: none;
}
/* @media only screen and (orientation: portrait) and (max-aspect-ratio: 9/16) {
  #input-name {
    width: 40vw !important;
  }

  #input-phone {
    width: 40vw !important;
  }

  #input-mail {
    width: 40vw !important;
  }
} */

/* #input-name {
  width: 27vw;
}

#input-phone {
  width: 27vw;
}

#input-mail {
  width: 27vw;
}

#input-text {
  width: 70vw;
  height: 200px;
  resize: none;
} */

.feedback-submit-btn {
  align-items: center;
  padding: 12px;
  background: #3c3e72;
  border-radius: 5px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 13) + 13px);
  color: #ffffff;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.select-none {
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.thanks-text {
  font-weight: 500;
  font-size: calc((100vw - 320px) / (1412 - 320) * (26 - 23) + 23px);
  color: #3c3e72;
  letter-spacing: 1.2px;
  font-family: Inter;
}
</style>
