<template>
  <section class="feedback-section">
    <h1>Обратная связь</h1>
    <div class="feedback-blocks-wrapper">
      <div class="input-label">
        <h1>ФИО</h1>
        <input id="input-name" type="text" />
      </div>
      <div class="input-label">
        <h1>Контактный телефон</h1>
        <input id="input-phone" type="text" />
      </div>

      <div class="input-label">
        <h1>Электронная почта</h1>
        <input id="input-mail" type="text" />
      </div>
      <div class="input-label">
        <h1>Описание</h1>
        <textarea id="input-text"></textarea>
      </div>
    </div>
    <div class="feedback-submit-btn select-none">Отправить запрос</div>
  </section>
</template>

<script>
export default {
  name: "FeedbackView",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro&family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Space+Grotesk:wght@400;500;600;700&display=swap");

.feedback-section {
  padding: 2vw 10vw;
  gap: 25px;
  display: flex;
  flex-direction: column;
}

.feedback-section > h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 12) + 12px);
  color: #3c3e72;
}

.feedback-blocks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.feedback-name-number {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.input-label {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  width: 100%;
}
.input-label input {
  height: 3vw;
  min-height: 40px;
}
.input-label input::placeholder {
  border: none;
}
.input-label h1 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 10) + 10px);
  color: #000;
}

.input-label input,
textarea {
  outline: none;
  width: 100%;
  border: 1px solid #3c3e729a;
  border-radius: 1px;
  align-items: center;
  padding: 15px;
}

.input-label input::placeholder,
.input-label input,
textarea {
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 10) + 10px);

  color: #000;
  font-family: Istok Web;

  font-style: normal;
  font-weight: 500;
}

.feedback-submit-btn {
  align-items: center;
  padding: 12px;
  background: #3c3e72;
  border-radius: 1px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.96px;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 10) + 10px);
  color: #ffffff;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 2.3vw;
  text-transform: uppercase;
  min-height: 40px;
}
.input-label h1 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 13) + 13px);
  color: #495057;
  position: absolute;
  top: -20%;
  display: flex;
  align-items: center;
  margin-left: 1vw;
  background-color: #fff;
  height: fit-content;
  width: fit-content;
  padding: 0 1vw;
}

.input-label textarea {
  height: 8.33vw;
  min-height: 120px;
  resize: none;
}

.input-label:nth-last-child(1) h1 {
  top: -8%;
}

.select-none {
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}
@media (min-width: 1440px) {
  .input-label h1 {
    font-size: calc(
      (100vw - 320px) / (2560 - 320) * (28.4 - 13) + 13px
    ) !important;
  }
  .feedback-blocks-wrapper {
    gap: 1.73vw;
  }
  .input-label:nth-last-child(1) h1 {
    top: -8%;
  }
}
</style>
