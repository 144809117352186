export default {
  namespaced: true,
  state: {
    products: [],
    filters: {
      minPrice: 0,
      maxPrice: 100000000,
    },
  },
  getters: {
    products: (state) => state.products,
    filters: (state) => state.filters,
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
  },
  actions: {
    async loadProducts({ state, commit }) {
      try {
        const queryString = Object.entries(state.filters)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");

        const url = `https://trade-orion.ru/profile/handlers/getproducts.php?${queryString}`;
        const response = await fetch(url);
        const products = await response.json();
        commit("setProducts", products);
      } catch (error) {
        console.error(error);
      }
    },
    async loadProductsBySection({ commit }, section) {
      try {
        const response = await fetch(
          `https://trade-orion.ru/profile/handlers/getproducts.php?section=${section}`
        );
        const products = await response.json();
        commit("setProducts", products);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
