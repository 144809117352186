<template>
  <header class="header-container">
    <router-link to="/">
      <a class="logo">
        <img src="@/assets/svg/logo2.svg" alt="" />
        <img src="@/assets/svg/logo-o.svg" alt="" />
      </a>
    </router-link>
    <div class="nav">
      <router-link to="/cart">
        <a
          :class="{
            'btn-catalog': $route.path === '/cart',
            btn: $route.path !== '/cart',
          }"
          >корзина</a
        >
      </router-link>
      <router-link to="/shop">
        <a
          :class="{
            'btn-catalog': $route.path === '/shop',
            btn: $route.path !== '/shop',
          }"
          >Каталог</a
        >
      </router-link>

      <router-link to="/feedback"
        ><a
          :class="{
            'btn-catalog': $route.path === '/feedback',
            btn: $route.path !== '/feedback',
          }"
        >
          заказ</a
        >
      </router-link>

      <router-link to="/contacts">
        <a
          :class="{
            'btn-catalog': $route.path === '/contacts',
            btn: $route.path !== '/contacts',
          }"
          >качество</a
        >
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.logo {
  display: flex;
  height: 4.16vw;
}

.logo img:nth-child(2) {
  display: none;
}
.logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.header-container > a {
  height: inherit;
}
.header-container {
  background-color: #433b64;
  width: 100%;
  height: 4.8vw;
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  position: sticky;
  box-shadow: 0px 0.347vw 0.347vw -0.347vw rgba(34, 60, 80, 0.6);
  top: 0;
  gap: 10px;
  z-index: 99;
}

/* .btn-catalog {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #ffff;
  letter-spacing: 3.375px;
  text-transform: uppercase; */
/* font-size: calc(12px + 8 * (100vw / 1412)); */
/* font-size: calc((100vw - 320px) / (1412 - 320) * (15 - 3.33) + 3.33px); */
/* font-size: 24px; */
/* cursor: pointer;
  text-decoration: none;
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
} */
.btn,
.btn-catalog {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #fff;
  letter-spacing: 3.375px;
  text-transform: uppercase;
  /* font-size: calc(10px + 10 * (100vw / 1412)); */
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 3.33) + 3.33px);
  /* font-size: 20px; */
  cursor: pointer;
  text-decoration: none;
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.nav {
  display: flex;
  align-items: center;
}

.nav a + * {
  margin-left: 3vw;
}

@media (orientation: portrait) {
  .header-container {
    padding: 0 2vw;
    height: 50px;
  }
  .nav > a:nth-child(1) {
    display: none;
  }
  .nav > a:nth-child(2) {
    margin-left: unset;
  }

  .logo {
    height: 14vw;
    max-height: 48px;
  }
  .logo img:nth-child(2) {
    display: unset;
  }
  .logo img:nth-child(1) {
    display: none;
  }
}
@media (max-width: 740px) {
  .logo img:nth-child(2) {
    display: unset;
  }
  .logo img:nth-child(1) {
    display: none;
  }
  .btn,
  .btn-catalog {
    font-size: calc((100vw - 320px) / (1440 - 720) * (15 - 12) + 12px);
  }
}
@media (max-width: 600px) {
  .nav > a:nth-child(1) {
    display: none;
  }
  .nav > a:nth-child(2) {
    margin-left: unset;
  }
  .header-container {
    justify-content: center;
  }
  .btn,
  .btn-catalog {
    font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 10) + 10px);
  }
  .nav a + * {
    margin-left: 5vw;
  }
}

@media (min-width: 740px) {
  .btn,
  .btn-catalog {
    font-size: calc((100vw - 320px) / (1440 - 720) * (15 - 12) + 12px);
  }
  .logo img:nth-child(2) {
    display: none;
  }
  .logo img:nth-child(1) {
    display: unset;
  }
}
</style>
