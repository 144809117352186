<template>
  <div style="background-color: #fff">
    <ShopHeader @search-updated="updateSearch" />
    <div class="catalog-content-box">
      <ShopFilters @filters-updated="updateFilters" />
      <ShopCategories />
      <ShopCatalog :products="filteredProducts" />
    </div>
  </div>
</template>

<script>
import ShopHeader from "@/components/Shop/HeaderComponent.vue";
import ShopCatalog from "@/components/Shop/CatalogComponent.vue";
import ShopFilters from "@/components/Shop/FiltersComponent.vue";
import ShopCategories from "@/components/Shop/sections/SectionsTest.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ShopHeader,
    ShopFilters,
    ShopCatalog,
    ShopCategories,
  },
  data() {
    return {
      BackUrl: "http://127.0.0.1:8081",
      minPrice: null,
      maxPrice: null,
      sortBy: null,
      searchValue: null,
    };
  },
  methods: {
    ...mapActions("products", ["loadProducts"]),
    updateSearch({ searchValue }) {
      this.searchValue = searchValue;
    },
    updateFilters({ minPrice, maxPrice, sortBy }) {
      this.minPrice = minPrice;
      this.maxPrice = maxPrice;
      this.sortBy = sortBy;
    },
  },
  mounted() {
    this.loadProducts();
  },
  computed: {
    ...mapGetters("products", ["products"]),
    filteredProducts() {
      let filtered = [...this.products];
      let minPrice = this.minPrice;
      if (filtered.length == 0) {
        filtered.push({
          id: "0",
          name: "Товары отсутствуют",
          description: "",
          price: "",
          cost: "",
          empty: true,
          images: [],
        });
      } else {
        if (this.minPrice == null) {
          minPrice = 0;
        }

        // Фильтрация по цене
        if (minPrice !== null) {
          filtered = filtered.filter((product) => product.price >= minPrice);
        }
        if (this.maxPrice !== null && this.maxPrice !== "") {
          filtered = filtered.filter(
            (product) => product.price <= this.maxPrice
          );
        }
        // Поиск по названию
        if (this.searchValue) {
          const searchQuery = this.searchValue.toLowerCase();
          filtered = filtered.filter((product) =>
            product.name.toLowerCase().includes(searchQuery)
          );
        }
        // Сортировка
        if (this.sortBy === "price") {
          filtered.sort((a, b) => a.price - b.price);
        } else if (this.sortBy === "name") {
          filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      return filtered;
    },
  },
  name: "ShopView",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&display=swap");
.product-title::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: #000;
}
* {
  scrollbar-gutter: stable;
}

.catalog-content-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2vw 7.8vw;
}
.catalog-filters-container {
  display: none;
  flex-direction: column;
  gap: 10px;
  padding: 17px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  margin: 5px;
  width: 20vw;
  margin-top: calc((35px + 3.3vw) * -1);
}
.catalog-filters-block {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;

  padding: 0 10px;
}
.catalog-filters-title {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 10) + 10px);
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 0.5px solid #ccc;
}
.catalog-filters-filter-row {
  font-size: calc((100vw - 320px) / (1412 - 320) * (14 - 12) + 12px);
  font-family: Open Sans;
  font-weight: 400;
  color: #495057;
  margin-bottom: 5px;
  cursor: pointer;
}
.catalog-product-grid {
  /* max-width: 891px; */
  width: 62vw;
  display: grid;
  align-content: flex-end;
  grid-template-columns: repeat(auto-fill, 19.7vw);
  column-gap: 17px;
  row-gap: 21px;
  justify-content: center;
  justify-items: center;
}
.catalog-product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.catalog-product-card > .line {
  width: 100%;
  border: 1px solid #adb5bd;
  margin: 10px 0;
}

.catalog-product-card-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 1.25vw;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.25);
  width: 19.7vw;
  /* max-width: 284px; */
  height: 32.6vw;
  /* max-height: 441px; */
  box-sizing: border-box;
}

.catalog-product-card-wrapper > .line {
  height: 100%;
  border: 1px solid #adb5bd;
}
.subheader-cart-btn-mobile {
  display: none !important;
}
.catalog-product-card .subheader-cart-btn {
  border-radius: 2px;
  background: #433b64;
  height: 2.56vw;
  box-sizing: border-box;
  display: flex;
  padding: 0;
  width: 100%;
  color: #fff;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (13 - 2.8) + 2.8px);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.117vw;
  text-transform: lowercase;
  justify-content: center;
  margin-top: 2.15vw;
}

.select-none {
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.catalog-product-card .subheader-cart-btn .active {
  display: none;
}

.product-card-img {
  object-fit: cover;
  width: inherit;
  /* max-width: 250px;
  max-height: 250px; */
  width: 17.36vw;
  height: 17.36vw;
  background-color: #d9d9d9;
}
.product-card-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.manufacturer {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  color: #495057;
}
.product-title {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (2560 - 320) * (23 - 10) + 10px);
  font-style: normal;
  font-weight: 400;
  line-height: 153.846%;
  letter-spacing: 0, 072vw;
  max-height: 3.47vw;
  scrollbar-width: none;
  overflow-y: scroll;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1.18vw;
}
.product-price {
  color: #000;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 10) + 10px);
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
  letter-spacing: 0.135vw;
  text-align: center;
  margin-top: 1.041vw;
}
.card-product-amount-wrapper {
  display: flex;
  flex-direction: row;

  align-items: center;
  height: 2.56vw;
  justify-content: center;
  margin-top: 2.15vw;
}

.amount-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.amount-paragraph {
  line-height: 24px;
  text-align: center;
  width: 4.027vw;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0.2vw;
  border: 0.0694vw solid #433b64;
  align-items: center;
  display: flex;
  justify-content: center;
}
.amount-paragraph h1 {
  color: #433b64;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (20 - 9.4) + 9.4px);
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  letter-spacing: 2.6px;
}

.card-amount-icons {
  display: flex;
  align-items: center;
  width: 2.56vw;
  height: 2.56vw;
  justify-content: center;

  box-sizing: border-box;

  cursor: pointer;
  border-radius: 3px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.13) inset;
}
.card-amount-icons img {
  width: 40%;
  height: 40%;
}

.in-cart-btn {
  text-decoration: underline;
  font-weight: 700;
  font-size: 12px;
  color: #3c3e72;
  font-family: Open Sans;
}

.none {
  display: none;
}

.filter-menu-active {
  background-color: #fff;
  padding: 2vw 5vw;
  box-shadow: 5px 0px 4px -4px rgba(34, 60, 80, 0.27);
  overflow-y: scroll;
  width: 39vw;

  transform: translate(100vw);
}

.overflow-hidden {
  overflow-y: hidden;
}

.catalog-menu-container::-webkit-scrollbar {
  width: 0px;
}
.catalog-menu-container::-webkit-scrollbar-track {
  background: transparent;
}
.catalog-menu-container::-webkit-scrollbar-thumb {
  border: transparent;
}
.catalog-menu-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffffea;
  gap: 8px;
  padding: 2vw 5vw;
  box-shadow: 5px 0px 4px -4px rgba(34, 60, 80, 0.27);

  border-left: 3px solid #3c3e72;

  transition: 0.5s;
  z-index: 1;

  width: 28vw;

  position: fixed;

  overflow-y: scroll;
  scrollbar-width: none;

  left: -100vw;
  transition: 0.5s;
  z-index: 1;
  height: 65vh;
  margin-top: -2.3vw;
}
.category-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.category-title {
  font-size: calc((100vw - 320px) / (1412 - 320) * (18 - 16) + 16px);
  color: #3c3e72;
  display: flex;
  justify-content: space-between;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
}

.category-title-arrow {
  width: fit-content;
  transform: rotate(-90deg);
  transition: 0.2s;
  cursor: pointer;
}

.category-title-arrow-active {
  transform: unset;
}

.subcategory-box {
  background-color: #f2f2f3;
  padding: 10px;
  display: none;
  flex-direction: column;
  gap: 5px;
}

.subcategory-box-active {
  display: flex;
}

.subcategory-title {
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 14) + 14px);
  color: #000;
  font-family: Inter;
  cursor: pointer;
}

.subcategory-title-active {
  color: #6567a3;
}

#CategoryBtn {
  margin-right: 1.5vw;
  margin-left: 0;
  display: flex;
}
#cartbtn {
  border: none;
}
#cartbtn img {
  height: 55%;
  margin: 0 auto;
}
#filter-btn {
  display: none;
}
@media (orientation: portrait) {
  .product-title {
    min-height: 31px;
  }
}
@media (max-width: 1140px) {
  .product-title {
    max-height: 4.47vw;
  }
}
@media (max-width: 760px) {
  .product-title {
    max-height: 5.47vw;
  }
}
@media (max-width: 1175px) {
  .catalog-product-grid {
    grid-template-columns: repeat(auto-fill, minmax(19.7vw, 1fr));
    justify-items: center;
  }
  .product-card-img {
    max-width: unset;

    width: 100%;
    height: 290px;
  }
  /* .catalog-content-box {
    padding: 5vw 2vw;
  } */
  .subheader-container {
    padding: 2vw 7.8vw;
  }
  .amount-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: unset;
  }
  .catalog-product-card-wrapper {
    width: 100%;
    max-width: unset;
    max-height: unset;
  }
  .catalog-product-card-wrapper {
    width: 19.7vw;
    height: 32.6vw;
    padding: 1.25vw;
  }
  .catalog-product-grid {
    grid-template-columns: repeat(auto-fill, minmax(19.7vw, 1fr));
    column-gap: 1.1vw;
    row-gap: 1.4vw;
  }
  .product-card-img {
    width: 17.3vw;
    height: 17.3vw;
  }
}

@media (max-width: 860px) {
  .catalog-product-grid {
    grid-template-columns: repeat(auto-fill, 200px);
    width: 100%;
  }
  .catalog-product-card-wrapper {
    width: 200px;
    height: fit-content;
  }
  .product-card-img {
    width: 100%;
    height: 20.9vw;
  }
  .search-container {
    height: 4.7vw;
  }
  .menu-bg {
    width: 4.7vw;
    border-radius: 0px 6px 6px 0px;
  }
  .catalog-settings {
    height: 4.7vw;

    border-radius: 6px;

    width: 4.7vw;
  }
  .search {
    border-radius: 6px 0 0 6px;
  }

  .card-amount-icons {
    width: 29px !important;
    height: 29px;
  }
  .search {
    width: 55vw;
  }
  .card-product-amount-wrapper {
    height: 29px;
  }
  .catalog-product-card .subheader-cart-btn {
    height: 29px;

    font-size: 12px;
  }

  .catalog-menu-container {
    margin: 0;
    padding: 2vw 5vw 5vw 5vw;
    height: 65vh;
  }

  .catalog-filters-title {
    font-size: 14px;
  }
  .filter-menu-active {
    width: 50vw;
  }
  .subheader-container {
    padding: 2vw 3.8vw;
  }
  .catalog-content-box {
    padding: 2vw 3.8vw;
  }
  .catalog-product-grid {
    column-gap: 10px;
    row-gap: 10px;
  }
  .catalog-filters-container {
    position: absolute;
    left: -100vw;
    transition: 0.5s;
    z-index: 1;
    padding: 2vw 5vw;
    border-left: 3px solid #3c3e72;
  }
  .search-container {
    justify-content: center;
    width: 100%;
  }
  .catalog-product-card {
    justify-content: unset;
  }
  .subheader-cart-btn-mobile {
    display: flex !important;
  }
  .subheader-cart-btn:nth-child(2) {
    display: none;
  }
  #filter-btn {
    display: flex;
  }
}

@media (max-width: 720px) {
  .product-card-img {
    height: 180px;
  }
  .catalog-menu-container {
    width: 65vw;
  }
}
@media (max-width: 560px) {
  .search-container {
    height: 6.7vw;
  }
  .catalog-settings {
    height: 6.7vw;

    width: 6.7vw;
  }
}
@media (max-width: 480px) {
  .catalog-product-grid {
    grid-template-columns: repeat(auto-fill, 160px);
  }
  .catalog-product-card-wrapper {
    width: 160px;
  }
  .product-card-img {
    height: 150px;
  }
  .catalog-menu-container {
    height: 77vh;
  }
  .search {
    width: 45vw;
    border-radius: 1.416vw 0px 0px 1.416vw;
  }
  .amount-paragraph {
    width: 9.027vw;

    border-radius: 1vw;
  }
  .product-title {
    margin-top: 3.18vw;
    max-height: 8.3vw;
    min-height: 8.3vw;
  }
  .catalog-product-card .subheader-cart-btn {
    margin-top: 5.15vw;
    height: 7.56vw;
    font-size: calc((100vw - 320px) / (1440 - 320) * (13 - 10) + 10px);
    letter-spacing: 1.69px;
  }
  .catalog-product-card-wrapper {
    padding: 2.25vw;
  }
  .card-product-amount-wrapper {
    margin-top: 5.15vw;
    height: 5.56vw;
  }
  .card-amount-icons {
    width: 29px;
    height: 100%;
  }
  .search-container {
    height: 33px;
  }
  .catalog-settings {
    width: 33px;
    height: 100%;
    min-height: 33px;
    min-width: 33px;
    border-radius: 1.416vw;
  }
  .menu-bg {
    width: 33px;
  }
  .search-btn {
    border-radius: 0px 1.416vw 1.416vw 0px;
  }
  .catalog-filters-container {
    margin-top: calc((2.7vw) * -1);
  }
  .catalog-filters-title {
    font-size: 11px;
  }
}
@media (max-width: 420px) {
  .catalog-menu-container {
    height: 74vh;
  }
}
@media (max-width: 350px) {
  .product-title {
    max-height: 9.7vw;
  }
}
/* @media (min-width: 1440px) {
  .catalog-product-grid {
    grid-template-columns: repeat(auto-fill, 284px);
  }
} */
</style>
