<template>
  <div class="catalog-filters-container">
    <div>
      <div>
        <!-- Фильтры по цене -->
        <label></label>

        <label></label>
      </div>
      <div></div>
    </div>
    <div class="catalog-filters-block">
      <div class="catalog-filters-title select-none">Минимальная цена:</div>
      <input
        class="filter-sel"
        type="number"
        v-model="minPrice"
        @input="applyFilters"
      />

      <div class="catalog-filters-title select-none">Максимальная цена:</div>
      <input
        class="filter-sel"
        type="number"
        v-model="maxPrice"
        @input="applyFilters"
      />

      <div class="catalog-filters-title select-none">Сортировать по:</div>
      <!-- Сортировка -->
      <select class="filter-sel" v-model="sortBy" @change="applySort">
        <option value="price">Цене</option>
        <option value="name">Названию</option>
      </select>
    </div>
    <!-- <div class="catalog-filters-block">
      <div class="catalog-filters-title select-none">Тип</div>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Бензиловый
      </label>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Дизельный
      </label>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Электрический
      </label>
    </div>
    <div class="catalog-filters-block">
      <div class="catalog-filters-title select-none">Фильтры</div>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Другое значение
      </label>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Другое значение
      </label>
    </div>
    <div class="catalog-filters-block">
      <div class="catalog-filters-title select-none">Назначение</div>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Назначение
      </label>
      <label class="catalog-filters-filter-row select-none">
        <input type="checkbox" />
        Назначение
      </label>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      minPrice: 0,
      maxPrice: null,
      sortBy: null,
    };
  },
  methods: {
    applyFilters() {
      this.$emit("filters-updated", {
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        sortBy: this.sortBy,
      });
    },
    applySort() {
      this.$emit("filters-updated", {
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        sortBy: this.sortBy,
      });
    },
  },
  name: "FiltersComponent",
};
</script>

<style>
.filter-sel {
  padding: 3px 10px;
  border-radius: 3px;
  font-family: Inter;
  /* font-size: calc((100vw - 320px) / (1440 - 320) * (13 - 2.8) + 2.8px); */
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: 1.69px;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.13) inset;
  border: none;
  height: 30px;
  margin-bottom: 16px;
  outline: none;
}
.filter-sel option {
  padding: 3px 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: 1.69px;
  height: 30px;
  margin-bottom: 16px;
}
</style>
