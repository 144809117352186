<template>
  <div class="catalog-product-grid">
    <Product :key="product.id" :product="product" v-for="product in products">
      <template v-slot:structure="{ inCart, count, addToCart, removeFromCart }">
        <div class="catalog-product-card-wrapper">
          <div class="catalog-product-card">
            <div class="product-card-img">
              <img
                v-if="product.images[0]"
                width="256"
                height="256"
                :src="product.images[0]"
                :alt="product.name"
              />
              <img
                v-else
                width="256"
                height="256"
                src="@/assets/img/DONGFENG2.jpg"
                :alt="product.name"
              />
            </div>
            <!-- <div class="manufacturer">Производитель</div> -->
            <router-link v-if="!product.empty" :to="`/product/${product.id}`">
              <div class="product-title">{{ product.name }}</div>
            </router-link>
            <div v-else class="product-title">{{ product.name }}</div>

            <div v-if="!product.empty" class="product-price">
              {{ beautifyNumber(product.price * 1) }} р
            </div>
            <div v-if="!product.empty">
              <div v-if="inCart" class="card-product-amount-wrapper">
                <div class="amount-container">
                  <div
                    @click="removeFromCart(product)"
                    class="card-amount-icons card-amount-minus"
                  >
                    <img src="@/assets/img/math-minus.svg" alt="" />
                  </div>
                  <div class="amount-paragraph">
                    <h1>{{ count }}</h1>
                  </div>
                  <div
                    @click="addToCart(product)"
                    class="card-amount-icons card-amount-plus"
                  >
                    <img src="@/assets/img/math-plus.svg" alt="" />
                  </div>
                </div>
                <router-link :to="`/cart`">
                  <!-- <h1 class="in-cart-btn">В корзине</h1> -->
                </router-link>
              </div>
              <div v-else>
                <div
                  @click="addToCart(product)"
                  class="subheader-cart-btn subheader-cart-btn-mobile"
                >
                  в корзину
                </div>
                <div @click="addToCart(product)" class="subheader-cart-btn">
                  Добавить в корзину
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Product>
  </div>
</template>

<script>
import Product from "@/components/Shop/ProductComponent.vue";

export default {
  components: { Product },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    beautifyNumber(number) {
      const formattedNumber = number;
      return formattedNumber.toLocaleString("en-US").replaceAll(",", " ");
    },
  },
  name: "CatalogComponent",
};
</script>

<style></style>
