<template>
  <section class="feedback-section">
    <RouterLink to="/cart">
      <!-- <h2 class="back-to-cart-btn">
        <img src="@/assets/svg/arrow-left.svg" alt="<" />
        Обратно к корзине
      </h2> -->
      <div class="back-to-catalog-wrapper">
        <img src="../assets/svg/Arrow1.svg" alt="" />
        <h1>в корзину</h1>
      </div>
    </RouterLink>
    <div class="checkout-block-wrapper">
      <div class="osel">
        <div>
          <!-- <h1>Оформление заказа</h1> -->
          <div class="feedback-blocks-wrapper">
            <div class="input-label">
              <h1>Имя</h1>
              <input
                class="input-order"
                id="input-name"
                type="text"
                v-model="name"
              />
            </div>
            <div class="input-label">
              <h1>Фамилия</h1>
              <input
                class="input-order"
                id="input-surname"
                type="text"
                v-model="surname"
              />
            </div>
            <div class="input-label">
              <h1>Номер телефона</h1>
              <input
                class="input-order"
                id="input-phone"
                type="text"
                v-model="phone"
              />
            </div>

            <div class="input-label">
              <h1>E-mail</h1>
              <input
                class="input-order"
                id="input-mail"
                type="text"
                v-model="email"
              />
            </div>
            <div class="input-label">
              <h1>Адрес</h1>
              <input
                class="input-order"
                id="input-mail"
                type="text"
                v-model="address"
              />
            </div>
            <div class="input-textarea">
              <h1>Дополнительная информация</h1>
              <textarea
                class="dop_info"
                id="dop_info"
                type="text"
                v-model="dopInfo"
              />
            </div>
            <div
              class="feedback-submit-btn select-none"
              @click="submitForm"
              :disabled="!isFormValid"
            >
              далее
            </div>
          </div>
        </div>
      </div>
      <div class="order-list">
        <MiniCart />
      </div>
    </div>
    <!-- <div class="final-cost">
      Итоговая стоимость: <span>{{ totalCartPrice }} Р</span>
    </div> -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import MiniCart from "@/components/Shop/MiniCartComponent.vue";
export default {
  mounted() {
    // Проверяем ориентацию экрана при загрузке страницы
    this.checkOrientation();

    // Проверяем ориентацию экрана при изменении его размера
    window.addEventListener("resize", this.checkOrientation);
  },

  components: { MiniCart },
  data() {
    return {
      name: "",
      surname: "",
      dopInfo: "",
      phone: "",
      email: "",
      address: "",
      sdek: null,
      samovivoz: false,
      isLandscape: false,
      PVZ: null,
      sdek_data: {},
      deliveryPrice: 0,
    };
  },
  computed: {
    cartItems() {
      return this.$store.state.cart.cart;
    },
    totalCartPrice() {
      return this.$store.getters["cart/totalCartPrice"];
    },
    isFormValid() {
      return this.name && this.phone && this.email && this.address;
    },
    ...mapGetters("cart", ["totalCartPrice"]),
  },
  methods: {
    checkOrientation() {
      this.isLandscape = window.innerWidth > window.innerHeight;
    },
    async submitForm() {
      if (this.isFormValid) {
        const formData = {
          name: `${this.name} ${this.surname}`,
          dopInfo: this.dopInfo,
          phone: this.phone,
          email: this.email,
          address: this.address,
          cartItems: this.cartItems,
          totalCartPrice: this.totalCartPrice,
        };

        try {
          const response = await fetch(
            "https://trade-orion.ru/profile/youkassa/newpay.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );

          if (response.ok) {
            // Данные успешно отправлены
            const text = await response.text();
            eval(text);
            console.log("Eval!");
          } else {
            // Обработка ошибки при отправке данных
            console.error("Error sending form data:", response.status);
          }
        } catch (error) {
          // Обработка ошибок сети или других ошибок при отправке данных
          console.error("Error sending form data:", error);
        }
      }
    },
  },
  name: "CheckoutView",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");
.strangeDiv {
  width: 100%;
}
.strangeDiv.active {
  width: 50% !important;
}
.feedback-section {
  padding: 2vw 10vw;
  gap: 25px;
  display: flex;
  flex-direction: column;
}
.cart-product-image {
  margin-left: 5px;
}
.checkout-block-wrapper {
  display: flex;
  justify-content: space-between;
}
.cart-product-block {
  max-height: 28vw;
  overflow-y: scroll;
  scrollbar-width: none;
}

@media (orientation: landscape) {
  .osel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.osel {
  width: 41.5vw;
}
.final-cost {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 14) + 14px);
  color: #3c3e72;
  border-bottom: 2px solid #3c3e72;
  align-self: baseline;
}

.feedback-section > h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 12) + 12px);
  color: #3c3e72;
}

.feedback-section > div > div > h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1412 - 320) * (24 - 12) + 12px);
  color: #3c3e72;
}

.back-to-cart-btn {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 10) + 10px);
  color: #3c3e72;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
  white-space: nowrap;
  transition: 0.1s;
  box-sizing: border-box;
  line-height: 100%;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
}

.back-to-cart-btn:hover {
  border-bottom: 1px solid #3c3e72;
  transition: 0.1s;
}

.feedback-blocks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.56vw;
}

.input-label {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 0.0694vw solid gray;
  gap: 1.38vw;
  width: 41.5vw;
}
::-webkit-input-placeholder {
  text-align: right;
}
:-moz-placeholder {
  text-align: right;
}
::-moz-placeholder {
  text-align: right;
}
:-ms-input-placeholder {
  text-align: right;
}
.input-textarea {
  margin-top: 30px;
}
.input-textarea textarea {
  width: 41.5vw;
  height: 8.61vw;
  resize: none;
  max-width: 80vw;
  min-height: 124px;
}
.input-label h1,
.input-textarea h1 {
  color: #808080;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 10) + 10px);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.28px;
  margin-bottom: 1.04vw;
  min-width: fit-content;
}
.feedback-blocks-wrapper input {
  text-align: right;
  padding-right: 20%;
  border: none;
  width: 100%;
}

.feedback-submit-btn {
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  background: #433b64;
  color: #fff;
  font-family: Inter;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 13) + 13px);

  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 2.56vw;
  width: 340px;
  height: 44px;
}

.select-none {
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}
@media (orientation: landscape) {
  .feedback-section {
    padding: 2vw 10vw 7vw 10vw;
  }
}
@media (max-width: 1030px) {
  .feedback-blocks-wrapper input {
    padding-right: 10px;
  }
  .feedback-blocks-wrapper {
    width: 80vw;
  }
  .order-list {
    display: none;
  }
  .osel {
    width: 100%;
  }
  .input-label {
    width: 100%;
  }
  .input-textarea textarea {
    width: 100%;
  }
  .feedback-section {
    padding: 5vw 10vw;
  }
}
@media (max-width: 480px) {
  .feedback-blocks-wrapper input {
    padding-right: 10px;
  }
  .feedback-section {
    padding: 5vw 10vw !important;
  }
  .feedback-submit-btn {
    margin: 0 auto;
  }
  .feedback-submit-btn {
    width: 100%;
  }
  .back-to-catalog-wrapper {
    display: flex;
  }
  .back-to-catalog-wrapper h1 {
    font-size: calc((100vw - 320px) / (1440 - 320) * (32 - 11) + 11px);
  }
  .back-to-catalog-wrapper img {
    height: 15px;
  }
  .feedback-submit-btn {
    margin-top: 20px;
  }
}
</style>
