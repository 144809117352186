import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import Contacts from "@/views/ContactsView.vue";
import Shop from "@/views/ShopView.vue";
import Cart from "@/views/CartView.vue";
import Product from "@/views/ProductView.vue";
import Feedback from "@/views/FeedbackView.vue";
import Checkout from "@/views/CheckoutView.vue";
import Thanks from "@/views/ThanksView.vue";
import Util from "@/views/UtilView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/thanks",
    name: "thanks",
    component: Thanks,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
  },
  {
    path: "/product/:id",
    name: "Product",
    component: Product,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/util",
    name: "Util",
    component: Util,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
