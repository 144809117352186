import Vuex from 'vuex'
import cart from './cart';
import products from './products';



const store = new Vuex.Store({
    modules: {
        cart,
        products
    },
})

export default store