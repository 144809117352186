<template>
  <div class="subheader-container">
    <div class="catalog-subtitle">
      <h1>Каталог</h1>
    </div>
    <div class="subheader-row">
      <!-- <div
        ref="filterBtn"
        id="filter-btn"
        style="display: none"
        class="menu-bg"
      >
        <img src="@/assets/img/filter-white.svg" alt="" />
      </div> -->

      <div class="search-container">
        <div id="CategoryBtn" class="menu-bg catalog-settings">
          <img src="@/assets/img/menu.svg?v=2" alt="" />
        </div>
        <input
          v-model="searchValue"
          @input="applySearch"
          placeholder="Поиск"
          class="search"
        />

        <div class="menu-bg search-btn">
          <img src="@/assets/img/search.svg" alt="" />
        </div>
        <div class="catalog-settings" id="filter-btn" style="display: none">
          <img src="@/assets/svg/funnel-fill.svg" alt="" />
        </div>
        <router-link :to="`/cart`">
          <div id="cartbtn" class="menu-bg catalog-settings">
            <img src="@/assets/svg/basket3-fill.svg" alt="" />
          </div>
        </router-link>
      </div>
      <!-- <div>
        <div class="subheader-cart-btn-container">
          <div class="menu-bg">
            <img src="@/assets/img/shopping-cart.svg" alt="" />
          </div>

          <div class="subheader-cart-btn select-none">
            <router-link to="/cart">Корзина</router-link>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="subheader-in-cart-products">
      Товаров на {{ totalCartPrice }}₽
    </div> -->
  </div>
  <!-- <ShopSections /> -->
</template>

<script>
// import ShopSections from "@/components/Shop/sections/SectionsTest.vue";

import { mapGetters } from "vuex";

export default {
  name: "ShopHeader",
  components: {
    // ShopSections,
  },
  computed: {
    ...mapGetters("cart", ["totalCartPrice"]),
  },
  data() {
    return {
      searchValue: null,
    };
  },
  methods: {
    applySearch() {
      this.$emit("search-updated", {
        searchValue: this.searchValue,
      });
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box !important;
}
.subheader-container {
  padding: 5px 7.8vw;
}

.subheader-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.menu-bg {
  object-fit: cover;

  width: 2.7vw;
  /* background-color: #3c3e72; */
  align-items: center;
  text-align: center;
  display: flex;

  cursor: pointer;
  border: 0.0694vw solid #3c3e72;
  border-radius: 0px 0.416vw 0.416vw 0px;
  border-left: 0;
}
.catalog-settings {
  object-fit: cover;

  min-width: 2.7vw;
  height: 2.7vw;
  background-color: #3c3e72;
  align-items: center;
  text-align: center;
  display: flex;

  cursor: pointer;
  border-radius: 0.416vw;
  box-sizing: border-box;
  margin-left: 1.5vw;
}
.catalog-settings img {
  width: 60%;
  margin: 0 auto;
}
.menu-bg img {
  height: 40%;

  margin: 0 auto;
}

.search-container {
  display: flex;
  height: 2.7vw;

  width: 62vw;
}
.catalog-subtitle {
  display: block;
  margin-top: 5vw;
  border-bottom: 0.0694vw solid #433b64;
  padding-bottom: 1.8vw;
  margin-bottom: 1.3vw;
}
.catalog-subtitle h1 {
  color: #433b64;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (32 - 10) + 10px);
  font-style: normal;
  font-weight: 700;
  line-height: 62.5%;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  cursor: pointer;
}
.search {
  width: 57vw;
  border: 0.0694vw solid #3c3e72;
  border-radius: 0.416vw 0 0 0.416vw;
  border-right: 0;
  padding: 0px 0px 0px 10px;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 12) + 12px);
  color: rgba(0, 0, 0, 0.52);
  font-weight: 400;
  font-family: Inter;
  box-sizing: content-box;
  outline: none;
  letter-spacing: 0.036vw;
}

.subheader-cart-btn-container {
  display: flex;
}

.subheader-cart-btn {
  width: fit-content;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 12) + 12px);
  font-family: Open Sans;
  font-weight: 700;
  color: #fff;
  background-color: #3c3e72;
  text-align: center;
  align-items: center;
  display: flex;
  padding: 6px 12px 6px 0px;
  border-radius: 0px 0.0694vw 0.0694vw 0px;
  cursor: pointer;
}
.search-container::placeholder {
  color: rgba(60, 62, 114, 0.5);
}

.search-container::-webkit-input-placeholder {
  color: rgba(60, 62, 114, 0.5);
}

.search-container::-ms-input-placeholder {
  color: rgba(60, 62, 114, 0.5);
}

.search-container:-moz-placeholder {
  color: rgba(60, 62, 114, 0.5);
  opacity: 1;
}

.search-container::-moz-placeholder {
  color: rgba(60, 62, 114, 0.5);
  opacity: 1;
}

.search-container:-ms-input-placeholder {
  color: rgba(60, 62, 114, 0.5);
}

.subheader-in-cart-products {
  color: #495057;
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  font-family: "Space Grotesk", sans-serif;
  text-align: right;
  cursor: pointer;
}

/* @media (orientation: portrait) {
  .subheader-container {
    padding: 5px 2vw;
  }
} */
</style>
