<template>
  <div class="your-order-wrapper">
    <h1>Ваш заказ</h1>
    <h2>
      {{ cartItems.length }}
      {{ declOfNum(cartItems.length, ["товар", "товара", "товаров"]) }}
    </h2>
  </div>
  <div class="cart-borderline"></div>
  <div>
    <div class="cart-summary-wrapper">
      <div class="cart-product-block">
        <Product
          :key="product.id"
          :product="product"
          v-for="product in cartItems"
        >
          <template v-slot:structure="{ count, addToCart, removeFromCart }">
            <div class="cart-product-row">
              <div class="cart-product-image">
                <img
                  v-if="product.images[0]"
                  width="256"
                  height="256"
                  :src="product.images[0]"
                  :alt="product.name"
                />
                <img
                  v-else
                  width="256"
                  height="256"
                  src="@/assets/img/DONGFENG.jpg"
                  :alt="product.name"
                />
              </div>
              <div class="cart-product-info-block">
                <div class="info-block-title-wrapper">
                  <router-link :to="`/product/${product.id}`">
                    <div class="info-block-product-title">
                      {{ product.name }}
                    </div>
                  </router-link>
                  <div class="info-block-producer">Производитель</div>
                  <div class="info-price-block">
                    {{ beautifyNumber(product.price * 1) }}
                    р
                  </div>
                  <div class="product-amount-wrapper">
                    <h1>{{ count }} шт.</h1>
                    <div class="product-amount_nav">
                      <div
                        @click="removeFromCart(product)"
                        class="product-amount_nav_minus"
                      >
                        <img src="@/assets/svg/dash.svg" alt="" />
                      </div>
                      <div
                        @click="addToCart(product)"
                        class="product-amount_nav_plus"
                      >
                        <img src="@/assets/svg/plus.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="cart-line"></div> -->
          </template>
        </Product>
      </div>
    </div>
    <div class="cart-summary_line2"></div>
    <div class="cart-summary_total-price">
      <h1>Итого:</h1>
      <h2>{{ beautifyNumber(totalCartPrice) }} p</h2>
    </div>
  </div>
</template>

<script>
import Product from "@/components/Shop/ProductComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: { Product },
  computed: {
    cartItems() {
      return this.$store.state.cart.cart;
    },
    ...mapGetters("cart", ["totalCartPrice"]),
  },
  methods: {
    slicestr(string, max_length) {
      if (string.length <= max_length) {
        return string;
      } else {
        return string.slice(0, max_length - 3) + "...";
      }
    },
    declOfNum(number, words) {
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
      ];
    },
    beautifyNumber(number) {
      const formattedNumber = number;
      return formattedNumber.toLocaleString("en-US").replaceAll(",", " ");
    },
  },
  name: "MiniCart",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");

section-cart {
  padding: 3.81vw 9vw;
  display: flex;
  flex-direction: column;
}

.order-list {
  max-width: 31.4vw;
}
.empty-cart-alert {
  padding: 2vh 10vw;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  white-space: nowrap;
  text-align: center;
  border: 3px dotted #57636a;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 90vw;
}
.empty-cart-alert .info-block-product-title {
  height: unset;
}
.empty-cart-alert h1 {
  color: #57636a;
}
.cart-product-block {
  display: flex;
  flex-direction: column;
  height: fit-content;
  /* max-width: 776px; */
  gap: 3.4vw;
}

.cart-product-row {
  height: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: 0;
  gap: 5.41vw;

  max-width: 31.4vw;
}
.product-amount-wrapper h1 {
  color: #717171;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (12 - 9) + 9px);
  font-style: normal;
  font-weight: 400;
}
.cart-product-image {
  height: 10.76vw;
  width: 10.76vw;
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  scrollbar-gutter: unset;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
}

.cart-product-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cart-product-info-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 15vw;
  justify-content: space-between;
}
.info-block-title-wrapper {
  display: flex;
  flex-direction: column;
  width: 15vw;
  margin-top: 0.972vw;
}
.info-block-product-title {
  color: #000;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (15 - 12) + 12px);
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;
  letter-spacing: 0.083vw;
  height: 2.83vw;
  height: 40px;
  overflow: scroll;
  scrollbar-width: none;
}
.info-block-producer {
  color: #555;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (12 - 9) + 9px);
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: 0.066vw;
}
.info-block-delete-btn-wrapper {
  display: none;
  flex-direction: row;
  gap: 14px;
  height: 24px;
  align-items: center;
}

.info-block-delete-btn-wrapper > img {
  object-fit: cover;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.info-block-delete-btn-wrapper > h1 {
  font-size: calc((100vw - 320px) / (1412 - 320) * (12 - 8) + 8px);
  color: #17181a;
  font-weight: 700;
  font-family: "Space Grotesk", sans-serif;
  cursor: pointer;
}

.info-block-amount-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.info-block-amount-wrapper > h1 {
  width: 18px;
  text-align: center;
  color: #04080f;
  font-size: calc((100vw - 320px) / (1412 - 320) * (14 - 8) + 8px);
  font-weight: 700;
  font-family: "Space Grotesk", sans-serif;
}

.amount-wrapper-svg {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;
  border: 1px solid #3c3e72;
  box-sizing: border-box;
  border-radius: 7px;
}

.cart-product-delivery-price-info-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-delivery-block {
  display: flex;
  flex-direction: column;
}

.info-delivery-block > h1 {
  color: #17181a;
  font-weight: 700;
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 10) + 10px);
  font-family: "Open Sans", sans-serif;
}

.info-delivery-block > h2 {
  color: #495057;
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1412 - 320) * (12 - 8) + 8px);
  font-family: "Open Sans", sans-serif;
}

.info-price-block {
  color: #000;
  font-family: Istok Web;
  font-size: calc((100vw - 320px) / (1440 - 320) * (14 - 11) + 11px);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.077vw;
  line-height: 142.857%;
}

.checkout-btn {
  background-color: #3c3e72;
  padding: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: calc((100vw - 320px) / (1412 - 320) * (16 - 9) + 9px);
  line-height: 100%;
  color: #ffffff;
  cursor: pointer;
}

.cart-price-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  position: fixed;
  text-align: right;
  right: 10vw;
  width: 300px;
}

.checkout-info {
  border: 1px solid #3c3e72;
  border-radius: 5px;
  padding: 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.final-price-container,
.delivery-container,
.adress-container {
  display: flex;
  justify-content: space-between;
}

.final-price-container h1,
.delivery-container h1 {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 10) + 10px);
  color: #04080f;
}

.delivery-container h2 {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1412 - 320) * (20 - 10) + 10px);
  color: #495057;
}

.adress-container h1 {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc((100vw - 320px) / (1412 - 320) * (12 - 8) + 8px);
  line-height: 15px;
  display: flex;
  color: #495057;
}

.cart-line {
  width: 100%;
  height: 1px;
  background-color: #adb5bd;
}

.product-amount-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.347vw;
}

.product-amount_nav {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.product-amount_nav > div {
  width: 31px;
  height: 21px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.product-amount_nav > div img {
  width: 60%;
  height: 80%;
  object-fit: cover;
}
.product-amount_nav_minus {
}
.product-amount_nav_plus {
}
.your-order-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.41vw;
  margin-top: 1.18vw;
}
.your-order-wrapper h1 {
  color: #433b64;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (20 - 17) + 17px);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.your-order-wrapper h2 {
  color: #433b64;
  font-family: Inter;
  font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 13) + 13px);
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
.cart-borderline {
  width: 100%;
  background-color: #000;
  height: 0.0694vw;
  margin-bottom: 3.68vw;
}
.back-to-catalog-wrapper {
  margin-bottom: 3.68vw;
}
.cart-summary-wrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  height: 27vw;
  overflow-y: scroll;
  scrollbar-width: none;
}
.cart-summary-wrapper::-webkit-scrollbar {
  display: none;
}
.cart-summary_product-list {
  display: flex;
  flex-direction: column;
  gap: 0.27vw;
  margin-bottom: 1.66vw;
}
.cart-summary_product-list_product-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
}
.cart-summary_product-list_product-wrapper h1,
.cart-summary_product-list_product-wrapper h2 {
  color: #7b7b7b;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: 0.3px;
}
.cart-summary_product-list_product-wrapper h1 {
  font-family: Istok Web;
  max-width: 70vw;
  overflow: hidden;
}
.cart-summary_product-list_product-wrapper h2 {
  font-family: Inter;
}
.cart-summary_products-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.833vw;
}
.cart-summary_products-price h1,
.cart-summary_products-price h2,
.cart-summary_delivery-price h1,
.cart-summary_delivery-price h2 {
  color: #000;
  font-family: Istok Web;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.846%;
  letter-spacing: 0.325px;
}

.cart-summary_products-price h2,
.cart-summary_delivery-price h2 {
  font-family: Inter;
}
.cart-summary_delivery-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.66vw;
}
.cart-summary_line {
  width: 100%;
  height: 0.0694vw;
  background-color: #000;
  margin-bottom: 6.8vw;
}
.cart-summary_line2 {
  width: 100%;
  height: 0.0694vw;
  background-color: #000;
  margin-top: 3.68vw;
  margin-bottom: 1.875vw;
}
.cart-summary_line:nth-child(1) {
  display: none;
}

.cart-summary_total-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.08vw;
}
.cart-summary_total-price h1,
.cart-summary_total-price h2 {
  color: #433b64;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.cart-summary_total-price h2 {
  font-family: Inter;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.027vw;
}
.cart-summary_next-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #433b64;
  border-radius: 0.347vw;
  width: 100%;
  height: 3.05vw;
  min-height: 44px;
  cursor: pointer;
}
.cart-summary_next-btn h1 {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.96px;
}
@media (orientation: portrait) {
  .empty-cart-alert {
    padding: 2vh 5vw;
    width: 80vw;
  }
}
@media (min-width: 1440px) {
  .your-order-wrapper h2 {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (28.4 - 16) + 16px);
  }
  .info-block-product-title {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (26.6 - 15) + 15px);
  }
  .info-block-producer {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (21.3 - 12) + 12px);
  }
  .info-price-block {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (24.88 - 14) + 14px);
  }
  .product-amount-wrapper h1 {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (21.3 - 12) + 12px);
  }
  .product-amount_nav > div {
    width: 2.15vw;
    height: 1.45vw;
  }
  .cart-summary_product-list_product-wrapper h1,
  .cart-summary_product-list_product-wrapper h2 {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (21.3 - 12) + 12px);
  }
  .cart-summary_total-price h1,
  .cart-summary_total-price h2 {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (35.5 - 20) + 20px);
  }
  .cart-summary_next-btn h1 {
    font-size: calc((100vw - 1440px) / (2560 - 1440) * (28.4 - 16) + 16px);
  }
  .info-block-product-title {
    height: 2.83vw;
  }
  .empty-cart-alert {
    border-radius: 0.694vw;

    border: 0.2vw dotted #57636a;
  }
}
@media (max-width: 1030px) {
  .cart-product-row {
    height: fit-content;
    margin-left: 0;
    width: 100%;
  }
  .info-block-title-wrapper {
    width: 100%;
  }
  .cart-product-image {
    height: 110px;
    width: 110px;
  }
  .cart-product-info-block {
    width: 60%;
  }
  .cart-price-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
    position: fixed;
    text-align: right;
    width: 300px;
    margin: 0 auto;
    margin-bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 5px;
  }
  .footer-container {
    margin-bottom: 140px;
  }
}
@media (max-width: 480px) {
  section-cart {
    padding: 16.6vw 6.41vw;
  }
  .info-block-title-wrapper {
    margin-left: 8.541vw;
  }
  .info-block-title-wrapper {
    width: 43vw;
  }
  .back-to-catalog-wrapper {
    display: none;
  }

  .cart-borderline {
    margin-bottom: 11.2vw;
    height: 1px;
  }
  .cart-product-row {
    margin-left: 0;
    width: inherit;
  }
  .info-block-product-title {
    height: 33px;
  }
  .cart-product-block {
    gap: 8.461vw;
    width: 100%;
  }
  .cart-summary_line:nth-child(1) {
    display: block;
  }
  .cart-summary_line {
    margin-bottom: 3.84vw;
    height: 1px;
  }
  .cart-summary_product-list {
    gap: 0.769vw;
    margin-bottom: 4.1vw;
  }
  .cart-summary_products-price {
    margin-bottom: 0.769vw;
  }
  .cart-summary_total-price {
    margin-bottom: 5.89vw;
  }
  .cart-summary_next-btn {
    border-radius: 6px;
  }
  .cart-product-row {
    gap: 3.589vw;
  }
  .cart-product-image {
    height: 110px;
    min-width: 110px;
  }
  .cart-product-info-block {
    width: 100%;
  }
  .empty-cart-alert {
    padding: 2vh 5vw;
    width: 100%;
  }
}
</style>
